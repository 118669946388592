import { Component, OnInit } from '@angular/core';
import { IProject } from 'core-client/core/interfaces/project.interface';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { IQuestionnaireStructure } from 'src/app/models/questionnaireStructure.model';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getRouteNameByIdentificator } from "../../../../../../services/helper.service";
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { IRole } from 'core-client/core/interfaces/role.interface';
import { SettingsComponent } from '../../../settings.component';

@Component({
  selector: 'app-project-settings-role-detail',
  templateUrl: './project-settings-role-detail.component.html',
  styleUrls: ['./project-settings-role-detail.component.scss']
})
export class ProjectSettingsRoleDetailComponent implements OnInit {

  
  changesInfoText: string = "";
  projectDataRestrictions: IProject['dataRestrictionById'];
  projectRouteRestrictions: IProject['routeRestrictions'];
  $roleId: Observable<string>;
  roleId: string;
  dataReady: boolean = false;
  routeAvailability: IProject['routeRestrictions'];
  comparisonStructureList: IComparisonStructure[];
  feedbackStructureList: IComparisonStructure[];
  questionnaireStructureList: IQuestionnaireStructure[];
  getRouteName = getRouteNameByIdentificator

  roleForm: FormGroup;
  objectKeys = Object.keys;

  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    private ar: ActivatedRoute,
    public settingsComponent: SettingsComponent
  ) {
    this.$roleId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('roleId')
      })
    )
    const obs = this.$roleId.pipe(
      switchMap(roleId => {
        return this.http.post(`/core/role/get/${roleId}`, {})
      })
    )
    obs.subscribe((data: IRole) => {
      this.name.setValue(data.roleName);
      this.roleId = data._id;
      this.projectDataRestrictions = data.dataRestrictionById !== undefined ? data.dataRestrictionById : {};
      this.projectRouteRestrictions = data.routeRestrictions !== undefined ? data.routeRestrictions : {};
      this.dataReady = true;
    })

    this.http.post(`/core/project/projectDataRestrictions`, {}).subscribe((data: any) => {
      this.routeAvailability = data.routeAvailability !== undefined ? data.routeAvailability : {};
    })
    this.http.post(`/app/comparisonStructure/list`, {}).subscribe((data: any) => {
      this.comparisonStructureList = data
    })
    this.http.post(`/app/questionnaireStructure/list`, {}).subscribe((data: any) => {
      this.questionnaireStructureList = data
    })
    this.http.post(`/app/feedbackStructure/list`, {}).subscribe((data: any) => {
      this.feedbackStructureList = data
    })
  }

  ngOnInit() {
    this.roleForm = this.fb.group({
      'name': ['', [
        Validators.required
        ]
      ]
    });
  }

  get name() { return this.roleForm.get('name') }

  updateRestrictions(event, id, type){
    if(!this.projectDataRestrictions[type]) {
      this.projectDataRestrictions[type] = []
    }
    if (!event.target.checked) {
      this.projectDataRestrictions[type].push(id);
    }else{
      for( var i = 0; i < this.projectDataRestrictions[type].length; i++){ 
        if ( this.projectDataRestrictions[type][i] === id) {
          this.projectDataRestrictions[type].splice(i, 1); 
          i--;
        }
     }
    }    
    this.changesInfoText = "Máte neuložené změny!";
  }

  checkControl(id, type){
    if(!this.projectDataRestrictions[type]){
      return true
    }
    return !this.projectDataRestrictions[type].includes(id)
  }

  updatePath(event, type){
    this.projectRouteRestrictions[type] = event.target.checked ? true : false;
    console.log(this.projectRouteRestrictions);    
    this.changesInfoText = "Máte neuložené změny!";
  }
  checkPath(type){
    return this.projectRouteRestrictions[type];
  }

  updateRole(){
    this.http.post(`/core/role/update/${this.roleId}`, {
      name: this.name.value,
      routeRestrictions: this.projectRouteRestrictions,
      dataRestrictionById: this.projectDataRestrictions
    }).subscribe((data: any) => {      
      this.changesInfoText = "Všechny změny uloženy";
    })
  }

  scrollTo(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from 'core-client/core/project.service';
import { IProject } from 'core-client/core/interfaces/project.interface';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  createProjectForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private project: ProjectService
  ) { }

  ngOnInit() {
    this.createProjectForm = this.formBuilder.group({
      'projectName': ['', [
        Validators.required
        ]
      ]
    });
  }

  get projectName() {return this.createProjectForm.get('projectName')} 

  createProject() : void {
    if (this.createProjectForm.invalid) {
      return;
    }
    this.loading = true;
    this.project.createProject(this.projectName.value).subscribe((response : IProject) => {
      this.router.navigate(['/p/'+ response._id]);
      this.loading = false
    });

    console.log(this.projectName.value);
  }
}

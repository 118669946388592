import { Component, Input, Output, EventEmitter } from '@angular/core';
import { themeList, Theme } from "../../../../theme/theme";

@Component({
  selector: 'theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent {

  themeList: Theme[];

  @Input() activeTheme: string;

  @Output() onChange = new EventEmitter<string>();

  constructor() {
    this.themeList = themeList;
    this.activeTheme = this.activeTheme ? this.activeTheme : this.themeList[0].name;
  }

  setTheme(theme: Theme) {
    this.activeTheme = theme.name;
    this.onChange.emit(this.activeTheme);
  }

}

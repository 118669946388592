import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayOfTimeBy30Minutes } from '../../helpers';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'open-hours',
  templateUrl: './open-hours.component.html',
  styleUrls: ['./open-hours.component.scss']
})
export class OpenHoursComponent implements OnInit {

  formGroup: FormGroup;
  formArray: FormArray;
  day: string;
  startHour: string;
  endHour: string;
  errorMessage = {
    is: false,
    message: ""
  }
  times: any = ArrayOfTimeBy30Minutes;

  @Output() onChange = new EventEmitter<any>();
  
  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    this.formArray = this.fb.array([])
    this.formGroup = this.fb.group({
      formArray: this.formArray
    })
  }

  addOpenHour() {
    if(this.openHoursAddValidator(this.day,Number(this.startHour), Number(this.endHour), this.formArray)){
      this.formArray.push(this.fb.group({
            'day': [{value: this.day, disabled: true}, [Validators.required]],
            'startHour': [{value: this.startHour, disabled: true}, [Validators.required]],
            'endHour': [{value: this.endHour, disabled: true}, [Validators.required]]
          }))
    }else{

    }
    this.onChange.emit(this.formArray.value);
  }

  removeOpenHour(index){
    this.formArray.removeAt(index);
    this.onChange.emit(this.formArray.value);
  }
  
  openHoursAddValidator(d,s,e,f){
    if(e <= s){
      this.errorMessage.is = true;
      this.errorMessage.message = "Čas od je menší než čas do";
      return false;
    }
    let a = f.controls;
    for(let k = 0;k < a.length;k++) {
      if(a[k].controls.day.value == d){
        if((s <= Number(a[k].controls.endHour.value)) && (e >= Number(a[k].controls.startHour.value)) ){
           this.errorMessage.is = true;
           this.errorMessage.message = "Časy se protínají";
           return false;
        }
      }
    };
    this.errorMessage.is = false;
    this.errorMessage.message = "";
    return true;
  }

}

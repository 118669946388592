import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ViewContainerRef, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { take } from "rxjs/operators";

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {
 
  private _active: boolean = false;
  private overlayRef: OverlayRef;
  private overlayConfig: OverlayConfig;
  private hasBeenShown : boolean = false;

  constructor(
    private overlay : Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
  ) { }

  @ViewChild('popup', { read: TemplateRef, static: true }) popup: TemplateRef<any>;

  @Input() canBeClosedAnywhere : boolean = true;
  
  @Input() popupYPosition : string = "bottom";
  
  @Input() popupXPosition : string = "right";

  @Output() isActiveChange = new EventEmitter();

  @Input() set isActive(active: boolean) {
    //this condition is here to prevents that setter being called multiple times
    if (this._active != active) {
      this._active = active;
      if (this._active) {
        if(!this.hasBeenShown) {
          this.overlayRef = this.overlay.create(this.overlayConfig);
          this.hasBeenShown = true;          
        }     
        this.overlayRef.attach(new TemplatePortal(this.popup,this.viewContainerRef));
        this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => this.hidePopup());
      }else{
        if(this.overlayRef){
          this.overlayRef.detach();
        } 
      }
      this.isActiveChange.emit(this._active);
    }
  };

  get isActive() {
    return this._active;
  };

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef.nativeElement.previousElementSibling)
      .withPositions(this.getConnectedPosition());

    this.overlayConfig = new OverlayConfig({
      hasBackdrop: this.canBeClosedAnywhere,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: positionStrategy,
      
    });
    
  }

  hidePopup() {
    this.isActive = false;
    if(this.overlayRef){
      this.overlayRef.detach();
    }    
  };

  getConnectedPosition() : ConnectedPosition[]{
    let connectedPosition: ConnectedPosition[] = [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    }];
    
    if(this.popupYPosition == "top"){
      connectedPosition[0]["originY"] = "top";
      connectedPosition[0]["overlayY"] = "bottom";
    }

    if(this.popupXPosition == "left"){
      connectedPosition[0]["originX"] = "end";
      connectedPosition[0]["overlayX"] = "end";
    }

    return  connectedPosition;
  }

  ngOnDestroy(){
    if(this.overlayRef){
      this.overlayRef.detach();
    }
  }
}

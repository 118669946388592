import { CanActivate,  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class FeedbackGuard implements CanActivate{
    constructor(
        private http: HttpClient
      ) { }

      canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.http.post('/app/settings/userRestrictions',{ projectId: next.params.projectId}).pipe(
          map((restriction: any) => {
            const routeRestrictions = restriction.roleRestrictions.feedback  !== undefined ? restriction.roleRestrictions.feedback : true;
            return !(!restriction.projectAvailability.feedback || !(routeRestrictions))
          })
        );
      }
    
}
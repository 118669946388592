export class Helpers {

  public getInitials(name: string, singleLetter: boolean = false): string {
    if(!name){
      return
    }    
    if(singleLetter){
      return name.trim().charAt(0);
    }
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  public validateEmail(email: string): boolean {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  public tiblitModuleList: TiblitModule[] = [
    { name: 'Task Manager', code: 't'},
    { name: 'Care Table', code: 'c'}
  ] 

  public ArrayOfTimeBy30Minutes: TimeSelect[] = [
    {label: '00:00', value: '000000'},
    {label: '00:30', value: '003000'},
    {label: '01:00', value: '010000'},
    {label: '01:30', value: '013000'},
    {label: '02:00', value: '020000'},
    {label: '02:30', value: '023000'},
    {label: '03:00', value: '030000'},
    {label: '03:30', value: '033000'},
    {label: '04:00', value: '040000'},
    {label: '04:30', value: '043000'},
    {label: '05:00', value: '050000'},
    {label: '05:30', value: '053000'},
    {label: '06:00', value: '060000'},
    {label: '06:30', value: '063000'},
    {label: '07:00', value: '070000'},
    {label: '07:30', value: '073000'},
    {label: '08:30', value: '080000'},
    {label: '08:30', value: '083000'},
    {label: '09:30', value: '090000'},
    {label: '09:30', value: '093000'},
    {label: '10:30', value: '100000'},
    {label: '10:30', value: '103000'},
    {label: '11:30', value: '110000'},
    {label: '11:30', value: '113000'},
    {label: '12:30', value: '120000'},
    {label: '12:30', value: '123000'},
    {label: '13:30', value: '130000'},
    {label: '13:30', value: '133000'},
    {label: '14:30', value: '140000'},
    {label: '14:30', value: '143000'},
    {label: '15:30', value: '150000'},
    {label: '15:30', value: '153000'},
    {label: '16:30', value: '160000'},
    {label: '16:30', value: '163000'},
    {label: '17:30', value: '170000'},
    {label: '17:30', value: '173000'},
    {label: '18:30', value: '180000'},
    {label: '18:30', value: '183000'}
  ]

}

interface TimeSelect {
  label: string,
  value: string
}

export interface TiblitModule {
  name: string,
  code: string
}
export function getInitials(name) {
  if(!name){
    return
  }
  let initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase(); 
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

export const ArrayOfTimeBy30Minutes = [
        {label: '00:00', value: '000000'},
        {label: '00:30', value: '003000'},
        {label: '01:00', value: '010000'},
        {label: '01:30', value: '013000'},
        {label: '02:00', value: '020000'},
        {label: '02:30', value: '023000'},
        {label: '03:00', value: '030000'},
        {label: '03:30', value: '033000'},
        {label: '04:00', value: '040000'},
        {label: '04:30', value: '043000'},
        {label: '05:00', value: '050000'},
        {label: '05:30', value: '053000'},
        {label: '06:00', value: '060000'},
        {label: '06:30', value: '063000'},
        {label: '07:00', value: '070000'},
        {label: '07:30', value: '073000'},
        {label: '08:30', value: '080000'},
        {label: '08:30', value: '083000'},
        {label: '09:30', value: '090000'},
        {label: '09:30', value: '093000'},
        {label: '10:30', value: '100000'},
        {label: '10:30', value: '103000'},
        {label: '11:30', value: '110000'},
        {label: '11:30', value: '113000'},
        {label: '12:30', value: '120000'},
        {label: '12:30', value: '123000'},
        {label: '13:30', value: '130000'},
        {label: '13:30', value: '133000'},
        {label: '14:30', value: '140000'},
        {label: '14:30', value: '143000'},
        {label: '15:30', value: '150000'},
        {label: '15:30', value: '153000'},
        {label: '16:30', value: '160000'},
        {label: '16:30', value: '163000'},
        {label: '17:30', value: '170000'},
        {label: '17:30', value: '173000'},
        {label: '18:30', value: '180000'},
        {label: '18:30', value: '183000'}
      ]
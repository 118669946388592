export const getRouteNameByIdentificator = (identificator) => {
  switch (identificator) {
    case "comparison":
      return "Srovnání pojištění";
      break;
    case "questionnaire":
      return "Dotazník";
      break;
    case "settings":
      return "Nastavení";
      break;
    case "feedback":
      return "Zpětná vazba";
      break;
    case "companyAdministration":
      return "Administrace společností";
      break;
    case "feedbackAdministration":
        return "Administrace zpětné vazby";
        break;
    default:
      return identificator;
      break;
  }
}
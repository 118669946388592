import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  sidenavOpen: boolean = true;
  @ViewChild(SettingsMenuComponent, { static: true }) settingsMenu: SettingsMenuComponent;

  constructor() { }

  ngOnInit() {
    this.closeSideNavOnSmallMonitorWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closeSideNavOnSmallMonitorWidth();
  }

  toggleMenu() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  closeSideNavOnSmallMonitorWidth() {
    this.sidenavOpen = window.innerWidth <= 1280 ? false : true;
  }

}

import { Component, OnInit, Input, Output, EventEmitter, Directive, HostListener, ElementRef } from '@angular/core';
import {COMMA} from '@angular/cdk/keycodes';

@Component({
  selector: 'chip',
  template: `<div class="chip">
              <ng-content></ng-content>
              <span *ngIf="removable" class="remove-icon" (click)="removeChip()"><i class="fas fa-times"></i></span>
            </div>`,
})
export class ChipComponent {

  constructor() { }

  @Input() removable : boolean = false;
  
  @Output() removed = new EventEmitter();

  removeChip(){
    this.removed.emit();
  }

}


@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {

  constructor() { }

}

@Directive({
  selector: '[chipInputTokenEnd]',
})
export class ChipInputDirective{

  constructor() { }

  @Output() chipInputTokenEnd = new EventEmitter();

  @Input() chipSeparatorKeyCodes : number[] = [COMMA]
  
  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(this.chipSeparatorKeyCodes.find(x=>x === event.keyCode)){
      event.preventDefault();
      this.chipInputTokenEnd.emit({
        value: event.target["value"],
      });
      event.target["value"]= "";
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'core-client/core/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      'email': ['', [
        Validators.required,
        Validators.email
        ]
      ],
      'password': ['', [
        Validators.required
        ]
      ]
    });
  }

  get email() { return this.registerForm.get('email') }
  get password() { return this.registerForm.get('password') }

  register() {
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.auth.registerUserWithEmailAndPassword(this.email.value, this.password.value).subscribe((response) => {
      console.log(response);
      this.loading = false
    })
  }

}

import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  
  socket: any = null;
  socketId: string = null;

  constructor() { }

  connect = async (token: string) => {
    const connection = await io.connect('', {
      query: 'token=' + token
    });
    await connection.on('connect', () => {
      console.log("connected!");
    });
    this.socket = connection;
  }

  listen = (event: string): Observable<any> => {
    return Observable.create(observer => {
      this.socket.on(event, data => { 
        observer.next(data); });
    });  
  }

  emitToRoom = (event: string, room: string, data: any) => {
    this.socket.emit(event, {data, room});
  }

  send(message){
    this.socket.emit('data', { data: message});
  }
}
import { Component, OnInit, Directive, Input, HostListener, ComponentRef, ElementRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder, ConnectedPosition, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {style, animate, transition, trigger} from '@angular/animations';

@Directive({ selector: '[customTooltip]' })
export class CustomTooltipDirective implements OnInit, OnDestroy {

  private overlayRef: OverlayRef;
  private positionStrategy: FlexibleConnectedPositionStrategy
  private hasBeenShown : boolean = false;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) { }

  @Input('customTooltip') text = '';
  @Input('tooltipPosition') position = 'top';

  @HostListener('mouseenter')
  show() {
    if(!this.hasBeenShown) {
      this.overlayRef = this.overlay.create( {positionStrategy : this.positionStrategy } ); 
      this.hasBeenShown = true;
    }
    const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  ngOnInit(): void {
    let connectedPosition: ConnectedPosition[];
    switch (this.position) {
      case "bottom":
        connectedPosition = [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }]
        break;
        case "left":
        connectedPosition = [{
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        }]
        break;
        case "right":
        connectedPosition = [{
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        }]
        break;
      default:
        connectedPosition = [{
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        }]
        break;
    }

    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(connectedPosition);
  }

  ngOnDestroy() {
    if(this.overlayRef){
      this.overlayRef.detach();
    }
  }
}

@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1})) 
      ])
    ])
  ]
})
export class CustomTooltipComponent {

  constructor() { }

  @Input() text = '';
}

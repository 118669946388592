import { Component, OnInit, OnDestroy, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { FeedbackComponent } from '../feedback.component';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FeedbackService } from 'src/app/services/feedback.service';
import { Subscription } from 'rxjs';
import * as moment from "moment";
import { filter } from 'rxjs/operators';
import { trigger, transition, animate, style } from '@angular/animations';
import { AuthenticationService } from 'core-client/core/authentication.service';
import { BasicChatComponent } from 'core-client/core/utilities/elements/basic-chat/basic-chat.component';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { IProductVersionUploadedFile } from 'src/app/models/productVersionUploadedFile.model';

@Component({
  selector: 'app-feedback-table',
  templateUrl: './feedback-table.component.html',
  styleUrls: ['./feedback-table.component.scss'],
  providers: [FeedbackService],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class FeedbackTableComponent implements OnInit, OnDestroy, AfterViewChecked {

  objectKeys = Object.keys;
  biggerChat: boolean = false;
  analyses: any;
  feedbackStructure: IComparisonStructure;
  loadingData: boolean = true;
  urlChangeSubscription: Subscription;
  filterData: any = {
    products: []
  }
  filterDataReady: boolean = false;
  displayedVersionState: string = "";
  currentUserName: string = "";
  currentUserId: string = "";
  currentProductVersionId: string = "";
  chatCriterionHeader: string = "";
  chatAnalysisMessages: any;
  chatAnalysisId: string = "";
  chatOpened: boolean = false;
  fileName: string = "";
  fileError: boolean = false;
  selectedFile: File = null;
  uploadedFiles: IProductVersionUploadedFile[] = [];
  private notificationAnalysisId: string = '';
  private notificationProductVersionId: string = '';

  constructor(
    public feedbackComponent: FeedbackComponent,
    private router: Router,
    private ar: ActivatedRoute,
    private feedbackService: FeedbackService,
    private elem: ElementRef,
    private authService: AuthenticationService,
    private http: HttpClient
  ) {
    this.ar.queryParams.subscribe(params => {
      this.notificationAnalysisId = params['analysisId'];      
      this.notificationProductVersionId = params['productVersionId'];
    });
    this.urlChangeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(e => {
      this.loadingData = true;
      this.feedbackService.listAnalyses().subscribe(data => {
        this.feedbackStructure = data.feedbackStructure;
        this.analyses = data.analyses;
        this.productVersionInitialize();
        if(this.notificationProductVersionId && this.notificationProductVersionId != this.currentProductVersionId){
          this.changeProductVersion(this.notificationProductVersionId);
        }
        this.loadingData = false;
      });

    })
  }

  @ViewChild('basicChat', { static: false }) basicChat: BasicChatComponent;

  ngAfterViewChecked() {
    if (this.filterDataReady) {
      this.toggleByFilter()
      this.filterDataReady = false;
    }
  }

  ngOnInit() {
    this.currentUserName = this.authService.getTokenPayload().displayName;
    this.currentUserId = this.authService.getTokenPayload()._id;
  }

  productVersionInitialize() {
    this.feedbackStructure.products.forEach(product => {
      if (product.state == "Live") {
        product.productVersions.forEach(productVersion => {
          if (productVersion.state == "Aktuální" || productVersion.state == "Neaktuální") {
            this.filterData.products = []; //aby byla vždy jen jedna
            this.filterData.products.push(productVersion.id);
            this.displayedVersionState = productVersion.state;
            this.currentProductVersionId = productVersion.id;
          }
        });
      }
    });
    this.feedbackService.loadProductVersionFiles(this.currentProductVersionId).subscribe((data: any) => {
      this.uploadedFiles = data;
      this.filterDataReady = true;
    })
  }

  toggleByFilter() {
    for (let el of this.elem.nativeElement.querySelectorAll(`[data-productversion]`)) {
      if (this.filterData.products.includes(el.getAttribute(`data-productversion`))) {
        el.style.display = 'flex';
      } else {
        el.style.display = 'none';
      }
    }
  }

  changeProductVersion(productVersionId: string) {
    this.filterData.products = [];
    this.filterData.products.push(productVersionId);
    this.currentProductVersionId = productVersionId;
    this.uploadedFiles = [];
    this.feedbackService.loadProductVersionFiles(this.currentProductVersionId).subscribe((data: any) => {
      this.uploadedFiles = data;
    })
    this.displayedVersionState = this.feedbackStructure.products[0].productVersions.find(pV => pV.id == productVersionId).state;
    this.toggleByFilter();
  }

  dateToProductFormat(date) {
    return moment(date, 'YYYY-MM-DD').format('D. M. YYYY');
  }

  onNavigate(url) {
    window.open(url, "_blank");
  }

  feedbackConsentChanged(analysisId: string, decision: "agree" | "disagree") {
    switch (decision) {
      case "agree":
        this.feedbackService.updateAnalysisConsent(analysisId, true).subscribe(data => {
          this.feedbackService.createNotification(
            this.feedbackStructure._id,
            this.feedbackStructure.products[0].id,
            'consent',
            'analysis',
            this.currentProductVersionId,
            analysisId
          ).subscribe(res => { console.log(res) });
        })
        break;
      case "disagree":
        this.feedbackService.updateAnalysisConsent(analysisId, false).subscribe(data => {
          this.feedbackService.createNotification(
            this.feedbackStructure._id,
            this.feedbackStructure.products[0].id,
            'disconsent',
            'analysis',
            this.currentProductVersionId,
            analysisId
          ).subscribe(res => { console.log(res) });
        })
        break;
    }
  }

  showChat(analysisId, criterionHeader, analysisMessages) {
    this.chatAnalysisId = analysisId;
    this.chatCriterionHeader = criterionHeader;
    this.chatAnalysisMessages = analysisMessages;
    this.chatOpened = true;
  }

  addMessage(message) {
    this.feedbackService.updateAnalysisChat(this.chatAnalysisId, message).subscribe(data => {
      this.feedbackService.createNotification(
        this.feedbackStructure._id,
        this.feedbackStructure.products[0].id,
        'comment',
        'analysis',
        this.currentProductVersionId,
        this.chatAnalysisId
      ).subscribe(res => { console.log(res) });
    })
  }

  reopenChat() {
    this.chatOpened = false;
    setTimeout(() => {
      this.chatOpened = true;
    }, 100);
  }

  chooseFile(event) {
    if (!event.target.files[0]) {
      this.fileName = "";
      this.fileError = false;
      return;
    }
    if (event.target.files[0].type === 'application/pdf' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      if (event.target.files[0].size <= 5242880) {
        this.selectedFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        this.fileError = false;
      }
      else {
        this.fileName = "Maximální povolená velikost souboru je 5MB!"
        this.fileError = true;
        this.selectedFile = null;
      }
    }
    else {
      this.fileName = "Nesprávný formát souboru!"
      this.fileError = true;
      this.selectedFile = null;
    }
  }

  updateFeedbackForFrontend(analysis: any, consent: boolean) {
    analysis.consent = consent;
    analysis.changedBy = this.currentUserName;
    analysis.changedAt = new Date();
  }

  removeConsent(analysisId) {
    this.feedbackService.removeAnalysisConsent(analysisId).subscribe(res => {
      this.feedbackService.createNotification(
        this.feedbackStructure._id,
        this.feedbackStructure.products[0].id,
        'consentRemoved',
        'analysis',
        this.currentProductVersionId,
        analysisId
      ).subscribe(res => { console.log(res) });
    })
  }

  uploadFile() {
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('productVersionId', this.currentProductVersionId)
    this.http.post('/app/feedback/uploadFileForProductVersion', fd, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(res => {
      if (res.type == HttpEventType.UploadProgress) {
        this.loadingData = true;
        if (res.loaded == res.total) {
          this.loadingData = false;
          this.fileName = ""
          this.fileError = false;
          this.selectedFile = null;
          this.feedbackService.createNotification(
            this.feedbackStructure._id,
            this.feedbackStructure.products[0].id,
            'upload',
            'analysis',
            this.currentProductVersionId,
            '-'
          ).subscribe(res => { console.log(res) });
          this.feedbackService.loadProductVersionFiles(this.currentProductVersionId).subscribe((data: any) => {
            this.uploadedFiles = data;
          });
        }
      }
    })
  }

  ngOnDestroy() {
    this.urlChangeSubscription.unsubscribe();
  }
}

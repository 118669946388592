import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { IComparisonStructure } from '../models/comparisonStructure.model';
import { IAnalysisFeedback } from '../models/analysisFeedback.model';
import { IAnalysis } from '../models/analysis.model';
import { ICriterionFeedback } from '../models/criterionFeedback.model';


@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    productTypeId: Observable<string>;
    productId: Observable<string>;
    feedbackStructure: Observable<IComparisonStructure>;
    constructor(
        private http: HttpClient,
        private ar: ActivatedRoute
    ) {
        this.productTypeId = this.ar.paramMap.pipe(
            map(params => {
                return params.get('feedbackProductTypeId');
            })
        )
        this.productId = this.ar.paramMap.pipe(
            map(params => {
                return params.get('feedbackProductId');
            })
        )
        this.feedbackStructure = this.productTypeId.pipe(
            switchMap(productTypeId => {
                return this.productId.pipe(
                    switchMap(productId => {
                        if(productId==null){
                            productId = "first";
                        }
                        return this.http.post<IComparisonStructure>(`/app/feedbackStructure/get/${productTypeId}/${productId}`, {});
                    })
                )
            })
        )
    }

    listAnalyses() {
        return this.feedbackStructure.pipe(
            switchMap((feedbackStructure: IComparisonStructure) => {
                let tempArray = [];
                feedbackStructure.products.forEach((product: IComparisonStructure['products'][0]) => {
                    if (product.productVersions !== undefined) {
                        product.productVersions.forEach((productVersion: IComparisonStructure['products'][0]['productVersions'][0]) => {
                            tempArray.push(productVersion.id);
                        })
                    }
                })
                return this.http.post('/app/analysis/listByArray', { productVersionList: tempArray }).pipe(
                    switchMap((analyses: IAnalysis[]) => {
                        let tempCriteria: string[] = [];
                        feedbackStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
                            criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
                                tempCriteria.push(criterion.id);
                            });
                        })
                        const filteredAnalyses = analyses.filter((a: any) => tempCriteria.includes(a.criterion));
                        let analysesIds: string[] = [];
                        analyses.forEach((analysis: IAnalysis | any) => {
                            analysesIds.push(analysis._id);
                        })
                        return this.http.post('/app/feedback/analysesListByArray', { analysesIds }).pipe(
                            map((analysesFeedback:IAnalysisFeedback[])=>{
                                return { filteredAnalyses, feedbackStructure, analysesFeedback }
                            })
                        )
                    })
                );
            })
        ).pipe(
            take(1),
            map(data => {
                let analysesObject = this.createAnalysisTableObject(data.filteredAnalyses, data.feedbackStructure, data.analysesFeedback);
                return { analyses: analysesObject, feedbackStructure: data.feedbackStructure };
            })
        )
    }

    listCriteria(){
        return this.feedbackStructure.pipe(
            switchMap((feedbackStructure: IComparisonStructure) => {
                let tempArray = [];
                feedbackStructure.products.forEach((product: IComparisonStructure['products'][0]) => {
                    if (product.productVersions !== undefined) {
                        product.productVersions.forEach((productVersion: IComparisonStructure['products'][0]['productVersions'][0]) => {
                            tempArray.push(productVersion.id);
                        })
                    }
                })
                return this.http.post('/app/analysis/listByArray', { productVersionList: tempArray }).pipe(
                    switchMap((analyses: IAnalysis[]) => {
                        let criteriaIds: string[] = [];
                        feedbackStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
                            criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
                                criteriaIds.push(criterion.id);
                            });
                        })
                        const filteredAnalyses = analyses.filter((a: any) => criteriaIds.includes(a.criterion));
                        return this.http.post('/app/feedback/criteriaListByArray', { criteriaIds }).pipe(
                            map((criteriaFeedback:ICriterionFeedback[])=>{
                                return { feedbackStructure, criteriaFeedback, filteredAnalyses }
                            })
                        );
                    })
                );
            })
        ).pipe(
            take(1),
            map(data => {
                let criteriaObject = this.createCriteriaTableObject(data.filteredAnalyses, data.feedbackStructure, data.criteriaFeedback);
                return { feedbackStructure: data.feedbackStructure , criteria: criteriaObject };
            })
        )
    }
    createAnalysisTableObject(analyses: IAnalysis[], feedbackStructure: IComparisonStructure, analysesFeedback:IAnalysisFeedback[]) {
        let tableObject = {};
        let orderObject = {};

        feedbackStructure.products.forEach((product: IComparisonStructure['products'][0]) => {
            orderObject[product.id] = product.defaultOrder;
        });

        feedbackStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
            orderObject[criterionCategory.id] = criterionCategory.defaultOrder;
            criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
                orderObject[criterion.id] = criterion.defaultOrder;
            });
        })

        analyses.forEach((analysis: IAnalysis | any) => {
            // Pokud se objevila chyba v Airtable neber vpotaz
            if (analysis.criterionCategory === null || analysis.criterion === null || analysis.product === null) {
                return;
            }

            if (tableObject[analysis.criterionCategory] === undefined) {
                tableObject[analysis.criterionCategory] = {
                    name: analysis.criterionCategoryName,
                    description: analysis.criterionCategoryDescription,
                    defaultOrder: orderObject[analysis.criterionCategory] !== undefined ? orderObject[analysis.criterionCategory] * 1000 : analysis.criterionCategoryDefaultOrder * 1000
                }
            }

            if (tableObject[analysis.criterionCategory]['productVersions'] === undefined) {
                tableObject[analysis.criterionCategory]['productVersions'] = {}

                tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] = {
                    data: false
                }
            }

            if (tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] === undefined || !tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion].data) {
                tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] = {
                    productName: analysis.productName,
                    defaultOrder: orderObject[analysis.product] != undefined ? (orderObject[analysis.product] * 1000 + (analysis.productVersionDefaultOrder * 10)) : (analysis.productDefaultOrder * 1000 + (analysis.productVersionDefaultOrder * 10)),
                    productId: analysis.product,
                    state: analysis.versionState,
                    effectiveDate: analysis.versionEffectiveDate,
                    materials: analysis.materials
                }
            }

            if (tableObject[analysis.criterionCategory]['criteria'] === undefined) {
                tableObject[analysis.criterionCategory]['criteria'] = {}
            }

            if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] === undefined) {
                tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] = {
                    name: analysis.criterionName,
                    description: analysis.criterionDescription,
                    defaultOrder: tableObject[analysis.criterionCategory].defaultOrder + (orderObject[analysis.criterion] != undefined ? orderObject[analysis.criterion] : analysis.criterionDefaultOrder) * 10
                }
            }

            if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'] === undefined) {
                tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'] = {}
                tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] = {
                    data: false
                }
            }

            if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] === undefined || !tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion].data) {
                const feedback = analysesFeedback.find(aF=>aF.analysisId == analysis._id);
                tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] = {
                    id: analysis._id,
                    state: analysis.state,
                    consent: feedback != undefined ? feedback.consent : undefined,
                    changedBy: (feedback != undefined && feedback.changedBy) ? feedback.changedBy['displayName'] : "",
                    changedAt: (feedback != undefined && feedback.changedAt )? feedback.changedAt : "",
                    messages: feedback != undefined ? feedback.messages : [],
                    description: analysis.description,
                    backgroundColor: analysis.backgroundColor,
                    moreInformation: analysis.moreInformation,
                    defaultOrder: orderObject[analysis.product] != undefined ? (orderObject[analysis.product] * 1000 + (analysis.productVersionDefaultOrder * 10)) : (analysis.productDefaultOrder * 1000 + (analysis.productVersionDefaultOrder * 10)),
                }
            }

        })
        return tableObject;
    }

    createCriteriaTableObject(analyses: IAnalysis[], feedbackStructure: IComparisonStructure, criteriaFeedback:ICriterionFeedback[]) {
        let tableObject = {};
        let orderObject = {};

        feedbackStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
            orderObject[criterionCategory.id] = criterionCategory.defaultOrder;
            criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
                orderObject[criterion.id] = criterion.defaultOrder;
            });
        })
        analyses.forEach((analysis: IAnalysis | any) => {
            if (analysis.criterionCategory === null || analysis.criterion === null || analysis.product === null) {
                return;
            }

            if (tableObject[analysis.criterionCategory] === undefined) {
                tableObject[analysis.criterionCategory] = {
                    name: analysis.criterionCategoryName,
                    description: analysis.criterionCategoryDescription,
                    defaultOrder: orderObject[analysis.criterionCategory] !== undefined ? orderObject[analysis.criterionCategory] * 1000 : analysis.criterionCategoryDefaultOrder * 1000
                }
            }

            if (tableObject[analysis.criterionCategory]['criteria'] === undefined) {
                tableObject[analysis.criterionCategory]['criteria'] = {}
            }

            if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] === undefined) {
                const feedback = criteriaFeedback.find(aF=>aF.criterionId == analysis.criterion);
                tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] = {
                    name: analysis.criterionName,
                    messages: feedback != undefined ? feedback.messages : [],
                    description: analysis.criterionDescription,
                    defaultOrder: tableObject[analysis.criterionCategory].defaultOrder + (orderObject[analysis.criterion] != undefined ? orderObject[analysis.criterion] : analysis.criterionDefaultOrder) * 10
                }
            }
        })
        
        return tableObject;
    }

    removeAnalysisConsent = (analysisId: string) => {
        return this.http.post(`/app/feedback/analysisRemoveConsent/${analysisId}`,{});
    }

    updateAnalysisConsent = (analysisId: string, consent: boolean) => {
        return this.http.post(`/app/feedback/analysisUpdateConsent/${analysisId}`, { consent });
    }

    updateAnalysisChat = (analysisId: string, message: any) => {
        return this.http.post(`/app/feedback/analysisAddNewMessage/${analysisId}`, { message });
    }

    updateCriterionChat = (criterionId: string, message: any) => {
        return this.http.post(`/app/feedback/criterionAddNewMessage/${criterionId}`, { message });
    }

    loadProductVersionFiles = (productVersionId: string) =>{
        return this.http.post('/app/feedback/productVersionUploadedFiles',{productVersionId : productVersionId})
    }

    createNotification = (
            productTypeId: string, productId: string, notificationType: string,
            feedbackType: string, productVersionId: string, activeItemId: string
        ) =>{
        return this.http.post(`/app/feedbackNotification/create`, {
            toProject: 'admin',
            productTypeId: productTypeId,
            productId: productId,
            notificationType: notificationType,
            feedbackType: feedbackType,
            productVersionId: productVersionId,
            activeItemId: activeItemId
          });
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fincentrum-login',
  templateUrl: './fincentrum-login.component.html',
  styleUrls: ['./fincentrum-login.component.scss']
})
export class FincentrumLoginComponent implements OnInit {

  constructor() { 
    window.location.href = "https://findata2011.fincentrum.com/CZ/Web/OAuth/Authorize?client_id=Eucs&redirect_uri=https%3A%2F%2Ffincentrum.e-srovnani.cz%2Fauth%2Fsso%2Ffincentrum%2Faccess&scope=~%2FGetInfo&response_type=code"
  }

  ngOnInit() {
  }

}

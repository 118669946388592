import { Component, OnInit } from '@angular/core';
import { IRole } from 'core-client/core/interfaces/role.interface';
import { HttpClient } from '@angular/common/http';
import { SettingsComponent } from '../../settings.component';

@Component({
  selector: 'app-project-settings-roles',
  templateUrl: './project-settings-roles.component.html',
  styleUrls: ['./project-settings-roles.component.scss']
})
export class ProjectSettingsRolesComponent implements OnInit {

  projectRoles: IRole[];

  constructor(
    private http: HttpClient,
    public settingsComponent: SettingsComponent
  ) { }

  ngOnInit() {
    this.http.post(`/core/project/projectRoles`, {}).subscribe((data:any) => {
      this.projectRoles = data;
    })
  }

}

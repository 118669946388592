import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'core-client/core/authentication.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private ar: ActivatedRoute,
    private http : HttpClient
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      'email': ['', [
        Validators.required,
        Validators.email
        ]
      ],
      'password': ['', [
        Validators.required
        ]
      ]
    });
    const subdomain : string = this.getSubdomain()
    if(subdomain == 'kapitol' || subdomain == 'srovnavaczivot'){
      this.router.navigate(['/auth/sso/kapitol']);
    }
    if(subdomain == 'fincentrum'){
      this.router.navigate(['/auth/sso/fincentrum']);
    }

    if(subdomain == 'swisslifeselect'){
      this.router.navigate(['/auth/sso/fincentrum']);
    }

    if(subdomain == 'swisslife'){
      this.router.navigate(['/auth/sso/swisslife']);
    }

  }

  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.auth.loginUserWithEmailAndPassword(this.email.value.toLowerCase(), this.password.value).subscribe((response) => {
      const accessing = this.ar.snapshot.queryParamMap.get('accessing');
      if(accessing){
        this.router.navigate([accessing]);
      }else{
        this.http.post(`/core/project/userProjects`, {}).subscribe((projects:any) => {
          let path =  "/p/"+projects[0].project;
          this.http.post(`/app/settings/userRestrictions`, {projectId: projects[0].project}).subscribe((data:any) => {
            if(data.comparisonPath){
              path +=  "/t/" + data.comparisonPath;
              this.router.navigate([path]);
            }else if(data.questionnairePath){
              path +=  "/q/" + data.questionnairePath;
              this.router.navigate([path]);
            }else{
              path +=  "/i/" + data.feedbackPath;
              this.router.navigate([path]);
            }
          })
        })
      }
      this.loading = false
    }, (error) => {
      alert("Špatné přihašovací údaje")
      this.loading = false
    })
  }

  getSubdomain() : string {
    let subdomain: string = ""
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www') {
      subdomain = '';
    } else {
      subdomain = domain.split('.')[0];
    }
    return subdomain;
  }

}

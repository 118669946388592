import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProductType } from 'src/app/models/productType.model';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'feedback-menu',
  templateUrl: './feedback-menu.component.html',
  styleUrls: ['./feedback-menu.component.scss'],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class FeedbackMenuComponent implements OnInit {

  productTypeList: Observable<any>;
  productTypes: IProductType[] = [];
  loadingData: boolean = true;
  constructor(private http: HttpClient, private ar: ActivatedRoute) { 
    this.productTypeList = this.http.post<any>('/app/feedbackStructure/menuList', {});
  }

  ngOnInit() {
    this.productTypeList.subscribe((data) => {
      this.productTypes = data;
      this.loadingData = false;
      this.openAll();
    })
  }


  openAll(){
    if(this.productTypes){
      this.productTypes.forEach((productType: any) => {
        productType.active = true;
      })
    }
  }
}

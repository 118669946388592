import { Injectable } from '@angular/core';
import { Observable, fromEvent, of, merge } from 'rxjs';
import { switchMap, filter, map, catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetstatService {

  networkStatus: Observable<boolean> = of(true);

  constructor(private http: HttpClient) {
    let online$ = fromEvent(window, 'online').pipe(
      switchMap(e => this.pingServer())
    );

    let offline$ = fromEvent(window, 'offline').pipe(
      switchMap(e => this.pingServer())
    );
    this.networkStatus = merge(
      online$,
      offline$
    )
  }

  private pingServer(): Observable<boolean>{
    return this.http.get('/core/ping').pipe(
      map((res:any) => {
        return res ? true : false;
      }),
      catchError((err) => {
        return of(false)
      })
    )
  }
}

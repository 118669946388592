import { Component, Input, ContentChildren, QueryList, Directive, ElementRef, ViewChild, Output, EventEmitter, AfterContentInit, ViewContainerRef, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { OverlayPositionBuilder, Overlay, OverlayRef, ConnectedPosition, OverlayConfig } from '@angular/cdk/overlay';
import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { TemplatePortal } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';

@Directive({
  selector: 'custom-select[required]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CustomSelectRequiredDirective, multi: true }
  ]
})
export class CustomSelectRequiredDirective implements Validator {

  validate(c: FormControl) {
    if (!c.value) {
      return {
        'required': 'Please select an option.'
      };
    }

    return null;
  }

}

@Directive({
  selector: 'custom-select-option'
})
export class CustomSelectOptionDirective {

  constructor() { }

  @Input() optionValue: string;
  @Input() optionContent: string;

  ngOnInit() {
  }

}

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomSelectComponent, multi: true },
  ]
})
export class CustomSelectComponent implements ControlValueAccessor, AfterContentInit {

  isDisabled: boolean = false;
  active: boolean = false;
  isOnTop: boolean = false;
  overlayRef: OverlayRef;
  overlayConfig: OverlayConfig;
  hasBeenShown: boolean = false;

  onChange: (value: string) => void;

  constructor(
    private elementRef: ElementRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) { }

  @ContentChildren(CustomSelectOptionDirective) options: QueryList<CustomSelectOptionDirective>;

  @ViewChild('customSelectOptions', { read: TemplateRef, static: true }) customSelectOptions: TemplateRef<any>;

  @Input() placeHolder: string = '-- vyber možnost --';

  @Input() value: string = undefined;

  @Output() change = new EventEmitter();

  ngAfterContentInit(): void {
    if (this.value != undefined) {
      this.selectOption(this.options.find(op => op.optionValue == this.value),true);
    }
  }

  ngOnInit(): void {
    const connectedPosition: ConnectedPosition[] = [{
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    }];

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(connectedPosition);

    this.overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: positionStrategy,

    });
  }

  selectOption(option: CustomSelectOptionDirective,preventEmit = false) {
    this.placeHolder = option.optionContent;
    if (this.onChange) {
      this.onChange(option.optionValue);
    }
    if(!preventEmit){
      this.change.emit(option.optionValue);
    }
    this.hideOptions();
  }

  toggleOptions() {
    if(this.active){
      this.hideOptions();
    }
    else{
      this.showOptions();
    }
  }

  showOptions(){
    if (!this.hasBeenShown) {
      this.overlayRef = this.overlay.create(this.overlayConfig);
      this.hasBeenShown = true;
    }
    this.overlayRef.attach(new TemplatePortal(this.customSelectOptions, this.viewContainerRef));
    this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => this.hideOptions());    
    this.syncWidth();
    this.active = true;
  }

  hideOptions() {
    this.active = false;
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  syncWidth() {
    if (!this.overlayRef) {
      return;
    }
    const style = window.getComputedStyle(this.elementRef.nativeElement, null); //revize
    const refRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
    this.overlayRef.overlayElement.style.fontSize = style.getPropertyValue("font-size");
  }

  writeValue(value: any): void { }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

}

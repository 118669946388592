import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FeedbackMenuComponent } from './feedback-menu/feedback-menu.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  
  sidenavOpen: boolean = true;
  @ViewChild(FeedbackMenuComponent, { static: true }) feedbackMenu: FeedbackMenuComponent;

  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closeSideNavOnSmallMonitorWidth();
  }

  ngOnInit() {
    this.closeSideNavOnSmallMonitorWidth()
  }

  toggleMenu(){
    this.sidenavOpen = !this.sidenavOpen;
  }

  closeSideNavOnSmallMonitorWidth() {
    this.sidenavOpen = window.innerWidth <= 1280 ? false : true;
  }
}

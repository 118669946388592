import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formGroup: FormGroup;
  formArray: FormArray;

  @Output() onChange = new EventEmitter<any>();

  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    this.formArray = this.fb.array([])
    this.formGroup = this.fb.group({
      formArray: this.formArray
    })
  }

  addContact() {
    this.formArray.push(this.fb.group({
      'name': ['', [Validators.required]]
    }))
    console.log(this.formGroup);
    this.onChange.emit(this.formArray.value);
  }

  removeContact(index) {
    this.formArray.removeAt(index);
    this.onChange.emit(this.formArray.value);
  }

}

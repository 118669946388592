import { Component, OnInit, ViewChild, AfterContentInit, ContentChildren, QueryList, ElementRef, Renderer2, Input, ChangeDetectorRef } from '@angular/core';
import {style, state, animate, transition, trigger} from '@angular/animations';

@Component({
  selector: 'tab',
  template: `<div class="tab" *ngIf="active" >
              <ng-content>
              </ng-content>
            </div>`,
            animations: [
              trigger('fadeInOut', [
                transition(':enter', [   // :enter is alias to 'void => *'
                  style({opacity:0}),
                  animate(500, style({opacity:1})) 
                ]),
                transition(':leave', [   // :leave is alias to '* => void'
                  animate(500, style({opacity:0})) 
                ])
              ])
            ],
            styleUrls: ['./tabs.component.scss'],
})
export class TabComponent {

  active: boolean = false;

  constructor() {}

  deactivate(){
    return this.active = false;
  }

  activate(){
    return this.active = true;
  }
}

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  
})
export class TabsComponent implements AfterContentInit{

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  private _selectedIndex: number = 0;

  @Input() set selectedIndex(value: number){
    this._selectedIndex = value;
    if(this.tabs){
      this.tabs.forEach((tab,i) => {
        if(value == i){
          tab.activate()
        }else{
          tab.deactivate()
        }
      });
    }
  }

  ngAfterContentInit(){
    this.tabs.forEach((tab,i) => {
      if(i == this._selectedIndex)tab.activate();
    })
    
    console.log(this.tabs.length + " tab legth");
  }

  next() {
    this.tabs[++this._selectedIndex].active = this.tabs[this._selectedIndex-1].deactivate();
  }

  prev() {
    this.tabs[--this._selectedIndex].active = this.tabs[this._selectedIndex+1].deactivate();
  }

}

import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'core-client/core/project.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'analytics-menu',
  templateUrl: './analytics-menu.component.html',
  styleUrls: ['./analytics-menu.component.scss']
})
export class AnalyticsMenuComponent implements OnInit {
  
  logoImage: string = '';
  routeRestrictions: any;

  constructor(
    private http: HttpClient,
    private ar : ActivatedRoute
  ) { 
    this.http.post(`/app/settings/userRestrictions`, {}).subscribe((data:any) => {
      this.routeRestrictions = data;
    })
  }

  ngOnInit() {
    if(this.ar.snapshot.children.length > 0){
      this.logoImage = '/assets/images/'+ this.ar.snapshot.children[0].paramMap.get('projectId') +'.png';
    }
  }

}

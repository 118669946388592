import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swisslife-login',
  templateUrl: './swisslife-login.component.html',
  styleUrls: ['./swisslife-login.component.sass']
})
export class SwisslifeLoginComponent implements OnInit {

  constructor() { 
    window.location.href = "https://apps.swisslifeselect.cz/OAuthServer/oauth/v2/auth?client_id=41_46qva9hfqzswog0k48owws0ks44g0s4goskgsgscsw04o8kg80&response_type=code&redirect_uri=https://swisslife.e-srovnani.cz/auth/sso/swisslife/access";
  }

  ngOnInit() {
  }

}

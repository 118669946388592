import { Component, ChangeDetectorRef, ViewChild, ElementRef, AfterViewChecked, OnInit, OnDestroy } from '@angular/core';
import { QuestionnaireComponent } from '../questionnaire.component';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { IQuestionnaireStructure } from 'src/app/models/questionnaireStructure.model';
declare var jsPDF: any;
import html2canvas from "html2canvas";
import { CAndQPdfMakerComponent, PdfType } from 'src/app/components/utilities/c-and-q-pdf-maker/c-and-q-pdf-maker.component';
import { IFilterData, IUserComparisonView } from 'src/app/models/userComparisonView.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { IProject } from 'core-client/core/interfaces/project.interface';

@Component({
  selector: 'app-questionnaire-questions',
  templateUrl: './questionnaire-questions.component.html',
  styleUrls: ['./questionnaire-questions.component.scss'],
  providers: [QuestionnaireService],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class QuestionnaireQuestionsComponent implements AfterViewChecked, OnInit, OnDestroy {

  @ViewChild('resultTable', { static: false }) resultTable: ElementRef;
  @ViewChild('resultQuestions', { static: false }) resultQuestions: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;

  questionnaireStructure: IQuestionnaireStructure;
  analyses: any;
  autoTab: boolean = true;

  userQuestionnaireView: IUserComparisonView;  
  projectDataRestrictions: IProject['dataRestrictionById'];

  filterProducts: boolean = false;
  filterCriteria: boolean = false;
  filterData: IFilterData = {
    products: [],
    productsCount: 0,
    productsLength: 0,
    criteria: [],
    criteriaCount: 0,
    criteriaLength: 0,
    historicalVersions: [],
    answers: {}
  }
  filterDataReady: boolean = false;
  loadingData: boolean = true;

  saveViewForm: FormGroup;

  criteriaCategoryTabIndex: number = 0;

  urlChangeSubscription: Subscription;
  pdfmaker: CAndQPdfMakerComponent;
  pdfResultsMode: boolean = false;
  pdfQuestionsMode: boolean = false;
  savePopup : boolean = false;

  constructor(
    private questionnaireService: QuestionnaireService,
    public questionnaireComponent: QuestionnaireComponent,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private ar: ActivatedRoute,
    private http: HttpClient
  ) {
    this.urlChangeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(e => {
      this.loadingData = true;
      this.questionnaireService.listAnalyses([]).subscribe(data => {
        this.criteriaCategoryTabIndex = 0;
        this.questionnaireStructure = data.questionnaireStructure;
        this.analyses = data.analyses;
        this.userQuestionnaireView = data.userQuestionnaireView;
        this.name.setValue(data.userQuestionnaireView.name)
        for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
          const criterionCategory = this.questionnaireStructure.criterionCategories[i];
          criterionCategory['tabIndex'] = i;
          criterionCategory['criterionTabIndex'] = 0;
          for (let j = 0; j < criterionCategory.criteria.length; j++) {
            const criterion = criterionCategory.criteria[j];
            criterion['tabIndex'] = j;
            criterion['answered'] = 'x';
          }
        }
        this.userViewInitialize(this.userQuestionnaireView);
        this.cd.detectChanges();
        this.loadingData = false;
      })
    })
    this.http.post(`/core/project/projectDataRestrictions`, {}).subscribe((data: any) => {
      this.projectDataRestrictions = data.dataRestrictionById == undefined? {} : data.dataRestrictionById;
    })
  }

  ngAfterViewChecked() {
    if (this.filterDataReady) {
      console.log("toggle filter viewChecked")
      this.toggleByFilter()
      this.filterDataReady = false;
      for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
        const criterionCategory = this.questionnaireStructure.criterionCategories[i];
        criterionCategory['tabIndex'] = i;
        criterionCategory['criterionTabIndex'] = 0;
        let continueToNextQuestion = true;
        for (let j = 0; j < criterionCategory.criteria.length; j++) {
          const criterion = criterionCategory.criteria[j];
          criterion['tabIndex'] = j;

          if (continueToNextQuestion && this.userQuestionnaireView.answers && this.userQuestionnaireView.answers[criterionCategory.id] && this.userQuestionnaireView.answers[criterionCategory.id][criterion.id] && this.userQuestionnaireView.answers[criterionCategory.id][criterion.id] != 'x') {
            
            this.selectAnswer(i, j, this.userQuestionnaireView.answers[criterionCategory.id][criterion.id])
            if(this.questionnaireStructure.criterionCategories.length == i+1 && criterionCategory.criteria.length == j+1){
              this.criteriaCategoryTabIndex = this.questionnaireStructure.criterionCategories.length+1;
              console.log("to last")
            }
          } else {
            continueToNextQuestion = false;
            criterion['answered'] = 'x';
          }

        }
      }
      this.cd.detectChanges();
    }
  }

  ngOnInit() {
    this.saveViewForm = this.fb.group({
      'name': ["", [
        Validators.required,
      ]
      ]
    });
    
    this.pdfmaker = CAndQPdfMakerComponent.staticCAndQPdfMaker;
  }

  ngOnDestroy() {
    this.urlChangeSubscription.unsubscribe();
    this.pdfmaker.detach();
  }

  get name() { return this.saveViewForm.get('name') }

  productFilterAll(products, checked: boolean){
    const simulatedEvent = {
      target: {
        checked: checked
      }
    }
    products.forEach(product => {
      this.filterItem(simulatedEvent,product.id, 'product')
    })
  }

  filterItem(event, id, type, criteriaCategoryIndex = 0) {
    if (event.target.checked) {
      if (type == 'product') {
        if(!this.filterData.products.includes(id)){
          this.filterData.products.push(id);
          this.filterData.productsLength++
        }
      } else {
        this.filterData.criteria.push(id);
      }
    } else {
      if (type == 'product') {
        const indexOfProductElement = this.filterData.products.indexOf(id);

        if (indexOfProductElement > -1) {
          this.filterData.products.splice(indexOfProductElement, 1);
          this.filterData.productsLength--;
        }
      } else {
        const indexOfCriterionElement = this.filterData.criteria.indexOf(id);
        if (indexOfCriterionElement > -1) {
          this.filterData.criteria.splice(indexOfCriterionElement, 1);
        }
        if (type == 'criterion') {
          let c = 0;
          for (let i = 0; i < this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria.length; i++) {
            const criterion = this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria[i];
            if (this.filterData.criteria.includes(criterion.id)) {
              c++;
            }
          }
          if (c == 0) {
            const indexOfCriterionCategoryElement = this.filterData.criteria.indexOf(this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].id);
            if (indexOfCriterionCategoryElement > -1) {
              this.filterData.criteria.splice(indexOfCriterionCategoryElement, 1);
            }
          }
        }
      }

    }
    this.filterData.criteriaLength = 0;

    let count = 0;
    for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
      const criteriaCategory = this.questionnaireStructure.criterionCategories[i];
      if (this.filterData.criteria.includes(criteriaCategory.id)) {
        for (let j = 0; j < criteriaCategory.criteria.length; j++) {
          const criterion = criteriaCategory.criteria[j];
          if (this.filterData.criteria.includes(criterion.id)) {

            count++;
          }
        }
      }
    }
    this.filterData.criteriaLength = count;
    this.toggleByFilter();
    this.cd.detectChanges();
  }

  filterCheck(id, type) {
    if (type == 'product') {
      return this.filterData.products.includes(id);
    } else {
      return this.filterData.criteria.includes(id);
    }

  }

  filterRestrictionCheck(id, type){
    if(!this.projectDataRestrictions[type]){
      return false
    }
    return this.projectDataRestrictions[type].includes(id)
  }

  parseAnswers(): void {
    let answers: any = {};
    this.questionnaireStructure.criterionCategories.forEach(criterionCategory => {
      criterionCategory.criteria.forEach(criterion => {
        if (criterion.answered) {
          if (answers[criterionCategory.id] == undefined) {
            answers[criterionCategory.id] = {};
          }
          answers[criterionCategory.id][criterion.id] = criterion.answered
        }
      })
    })
    this.filterData.answers = answers;
  }

  createUserView() {
    this.validateUnigueName();
    if(this.saveViewForm.invalid){
      return;
    }
    this.savePopup = !this.savePopup;
    this.parseAnswers();
    this.questionnaireService.createView({
      name: this.name.value,
      productFilter: this.filterData.products,
      criterionFilter: this.filterData.criteria,
      answers: this.filterData.answers,
      productType: this.questionnaireStructure._id,
      favourite: false
    }).subscribe((res: any) => {
      console.log(res);
      this.router.navigate([`../${res.data}`], { relativeTo: this.ar }).then(() => {
        this.questionnaireComponent.questionnaireMenu.reloadMenuData();
      })
    })
  }

  updateUserView() {
    this.validateUnigueName(true);
    if(this.saveViewForm.invalid){
      return;
    }
    this.savePopup = !this.savePopup;
    this.parseAnswers();
    this.questionnaireService.updateView({
      name: this.name.value,
      productFilter: this.filterData.products,
      criterionFilter: this.filterData.criteria,
      productType: this.questionnaireStructure._id,
      answers: this.filterData.answers,
      _id: this.userQuestionnaireView._id
    }).subscribe((res: any) => {
      console.log(res);
      this.questionnaireComponent.questionnaireMenu.reloadMenuData();      
      this.userQuestionnaireView.name = this.name.value;
    })
  }
  deleteUserView() {
    this.questionnaireService.deleteView(this.userQuestionnaireView._id).subscribe((res: any) => {
      console.log(res);
      this.router.navigate([`../all`], { relativeTo: this.ar }).then(() => {
        this.questionnaireComponent.questionnaireMenu.reloadMenuData();
      })
    })
  }

  toggleFavourite() {
    this.questionnaireService.toggleFavourite(this.userQuestionnaireView._id, this.userQuestionnaireView.favourite).subscribe((res: any) => {
      this.userQuestionnaireView.favourite = !res.favourite;
      this.questionnaireComponent.questionnaireMenu.reloadMenuData();
    })
  }

  toggleByFilter() {
    this.questionnaireStructure.products.forEach(product => {
      if (this.filterData.products.includes(product.id)) {
        product.hidden = false;
      } else {
        product.hidden = true;
      }
    })
    this.questionnaireStructure.criterionCategories.forEach(criterionCategory => {
      if (this.filterData.criteria.includes(criterionCategory.id)) {
        criterionCategory.hidden = false;
      } else {
        criterionCategory.hidden = true;
      }
      criterionCategory.criteria.forEach(criterion => {
        if (this.filterData.criteria.includes(criterion.id)) {
          criterion.hidden = false;
        } else {
          criterion.hidden = true;
        }
      })
    })
    this.checkScore()
  }

  checkScore() {
    this.questionnaireStructure.products.forEach((product, productIndex) => {
      product.productVersions.forEach((productVersion, productVersionIndex) => {
        if (productVersion['analysisState'] == undefined) {
          productVersion['analysisState'] = {}
        }
        let scoreAll = 0;
        for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
          const criterionCategory = this.questionnaireStructure.criterionCategories[i];

          if (criterionCategory[productVersion.id] == undefined) {
            criterionCategory[productVersion.id] = {}
          }
          criterionCategory[productVersion.id]['criteriaCategoryScore'] = 0;
          for (let j = 0; j < criterionCategory.criteria.length; j++) {
            const criterion = criterionCategory.criteria[j];
            if (criterion[productVersion.id] == undefined) {
              criterion[productVersion.id] = {}
            }
            if (criterionCategory.hidden || criterion.hidden) {
              productVersion['analysisState'][criterion.id] = '';
              criterion[productVersion.id]['answerValue'] = 0;
              criterion.answered = "";
            } else {
              criterionCategory[productVersion.id]['criteriaCategoryScore'] += criterion[productVersion.id] == undefined || criterion[productVersion.id]['answerValue'] == undefined ? 0 : criterion[productVersion.id]['answerValue'];
              scoreAll += criterion[productVersion.id] == undefined || criterion[productVersion.id]['answerValue'] == undefined ? 0 : criterion[productVersion.id]['answerValue'];
            }
          }
          if (criterionCategory.hidden) {
            criterionCategory[productVersion.id]['criteriaCategoryScore'] = 0;
          }
        }
        this.questionnaireStructure.products[productIndex].productVersions[productVersionIndex]['scoreCount'] = scoreAll;
      })
    })
    this.cd.detectChanges();
  }

  downloadImage() {
    html2canvas(this.resultTable.nativeElement, { scale: 2 }).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'esrovnani.png';
      this.downloadLink.nativeElement.click();
    });
  }

  showPdfPreview() {
    window.print()
  }

  enterPdfResultsMode(){
    this.pdfResultsMode = true;
    this.pdfmaker.attach(this.resultTable, PdfType.questionnaire);
  }

  enterPdfQuestionsMode(){
      this.pdfQuestionsMode = true;
      this.pdfmaker.attach(this.resultQuestions, PdfType.comparison);
  }
  exitPdfMode(){
      this.pdfQuestionsMode = false;
      this.pdfResultsMode = false;
      this.pdfmaker.headerText="";
      this.pdfmaker.detach();
  }


  nextTabNew(): void {
    if(!this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]){
      this.criteriaCategoryTabIndex++;
      while(this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].hidden){
        this.criteriaCategoryTabIndex++;
      }
      this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex'] = -1;
    }
    this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']++;

    if (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].criteria[this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']]) {
      if (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].criteria[this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']].hidden) {
        this.nextTabNew();
        
      }
    }else{
      this.criteriaCategoryTabIndex++;
      while(this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].hidden){
        this.criteriaCategoryTabIndex++;
      }
      this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex'] = -1;
      this.nextTabNew();
    }
    
  }

  prevTabNew(): void {
    this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']--;

    if (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].criteria[this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']]) {
      if (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].criteria[this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex']].hidden) {
        this.prevTabNew();
        
      }
    }else{
      this.criteriaCategoryTabIndex--;
      while(this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].hidden){
        this.criteriaCategoryTabIndex--;
      }
      this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex'] = this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].criteria.length;
      this.prevTabNew();
    }
  }

  nextTab(): void {
    do {
      this.criteriaCategoryTabIndex++
    } while (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1].hidden)
  }
  prevTab(): void {
    do {
      this.criteriaCategoryTabIndex--
    } while (this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex + 1].hidden)
  }

  nextCriterionTab(tabIndex): void {
    do {
      this.questionnaireStructure.criterionCategories[tabIndex]['criterionTabIndex']++;
    } while (this.questionnaireStructure.criterionCategories[tabIndex].criteria[this.questionnaireStructure.criterionCategories[tabIndex]['criterionTabIndex'] - 1].hidden)
  }
  prevCriterionTab(tabIndex): void {
    do {
      this.questionnaireStructure.criterionCategories[tabIndex]['criterionTabIndex']--;
    } while (this.questionnaireStructure.criterionCategories[tabIndex].criteria[this.questionnaireStructure.criterionCategories[tabIndex]['criterionTabIndex'] + 1].hidden)
  }

  categoryCriteriaLength(criteriaCategoryIndex) {
    let count = 0;
    for (let i = 0; i < this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria.length; i++) {
      const crierion = this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria[i];
      if (!crierion.hidden) {
        count++;
      }
    }
    return count;
  }

  stepNumber(i) {
    let adder = 0;
    for (let j = 0; j < this.questionnaireStructure.criterionCategories[i].criteria.length; j++) {
      const element = this.questionnaireStructure.criterionCategories[i].criteria[j];
      if (!element.hidden && element.answered != 'x') {
        adder++;
      }
    }
    return adder;
  }
  toTab(index, disabled): void {
    if (disabled) {
      return;
    } else {
      this.criteriaCategoryTabIndex = index;
      this.questionnaireStructure.criterionCategories[this.criteriaCategoryTabIndex - 1]['criterionTabIndex'] = -1;
      this.nextTabNew();
    }
  }

  selectAnswer(criteriaCategoryIndex, criterionIndex, answerValue) {
    this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria[criterionIndex].answered = answerValue;
    this.questionnaireStructure.products.forEach((product, productIndex) => {
      product.productVersions.forEach((productVersion, productVersionIndex) => {

        let countAll = 0;
        if (productVersion['analysisState'] == undefined)
          productVersion['analysisState'] = {};

        for (let i = 0; i < this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria.length; i++) {
          const criterion = this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].criteria[i];

          if (!criterion[productVersion.id]) {
            criterion[productVersion.id] = {}
          }
          if (criterion.answered == 'x') {
            //countAll = 0;
            productVersion['analysisState'][criterion.id] = 'x';
            criterion[productVersion.id]['answerValue'] = 0;
          } else {
            const analysis = this.analyses[this.questionnaireStructure.criterionCategories[criteriaCategoryIndex].id]['criteria'][criterion.id]['analyses'][productVersion.id];
            if (analysis == undefined) {
              //countAll = 0;
              productVersion['analysisState'][criterion.id] = 'x';
              criterion[productVersion.id]['answerValue'] = 0;
            } else {
              criterion[productVersion.id] = {};
              if (analysis.state == 'Aktuální') {
                criterion[productVersion.id]['answerValue'] = analysis[`answer${criterion.answered}`];
                countAll += analysis[`answer${criterion.answered}`];
              } else {
                criterion[productVersion.id]['answerValue'] = 0;
              }
              productVersion['analysisState'][criterion.id] = analysis.state;
            }
          }
        }
        this.questionnaireStructure.criterionCategories[criteriaCategoryIndex][productVersion.id] = {};
        this.questionnaireStructure.criterionCategories[criteriaCategoryIndex][productVersion.id]['criteriaCategoryScore'] = countAll;
        this.updateScore(productIndex, productVersionIndex, productVersion.id);
      });
    });
    this.checkScore()
    if (this.autoTab) {
      this.nextTabNew();
    }
  }


  updateScore(productIndex, productVersionIndex, productVersionId) {
    let scoreAll = 0;
    for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
      const criteriaCategory = this.questionnaireStructure.criterionCategories[i];
      if (criteriaCategory.hidden || criteriaCategory[productVersionId] === undefined) {
        continue;
      }
      scoreAll += criteriaCategory[productVersionId].criteriaCategoryScore;
    }
    this.questionnaireStructure.products[productIndex].productVersions[productVersionIndex]['scoreCount'] = scoreAll;
  }

  countColorResult(score) {
    let max = 0;
    for (let i = 0; i < this.questionnaireStructure.criterionCategories.length; i++) {
      const criterionCategory = this.questionnaireStructure.criterionCategories[i];
      if (criterionCategory.hidden) {
        continue;
      }
      for (let j = 0; j < criterionCategory.criteria.length; j++) {
        const element = criterionCategory.criteria[j];
        if (!element.hidden) {
          max++
        }
      }
    }
    const actual = score;
    const percentage = (100 * actual) / max;
    if (percentage <= 33) {
      return 'red';
    } else if (percentage > 33 && percentage <= 66) {
      return 'blue';
    } else if (percentage > 66 && percentage <= 100) {
      return 'green';
    } else {
      return 'white';
    }
  }

  countColor(score, categoryIndex) {
    let max = 0;
    for (let j = 0; j < this.questionnaireStructure.criterionCategories[categoryIndex].criteria.length; j++) {
      if (this.questionnaireStructure.criterionCategories[categoryIndex].hidden) {
        break;
      }
      const element = this.questionnaireStructure.criterionCategories[categoryIndex].criteria[j];
      if (!element.hidden) {
        max++
      }
    }
    const actual = score;
    const percentage = (100 * actual) / max;
    if (percentage <= 33) {
      return 'red';
    } else if (percentage > 33 && percentage <= 66) {
      return 'blue';
    } else if (percentage > 66 && percentage <= 100) {
      return 'green';
    } else {
      return 'white';
    }
  }

  userViewInitialize(userView: IUserComparisonView) {   
    this.exitPdfMode();
    this.filterData.criteria = [];
    this.filterData.products = [];
    this.filterData.criteriaCount = 0;
    this.filterData.criteriaLength = 0;
    this.filterData.productsCount = 0;
    this.filterData.productsLength = 0;

    if (userView._id === "all") {
      this.questionnaireStructure.products.forEach(product => {
        if (product.state == "Live") {
          this.filterData.products.push(product.id);
          this.filterData.productsCount++;
          this.filterData.productsLength++;
          product.productVersions.forEach(productVersion => {
            if (productVersion.state == "Aktuální") {
              this.filterData.products.push(productVersion.id);
            }
          });
        }
      });
      this.questionnaireStructure.criterionCategories.forEach(criterionCategory => {
        this.filterData.criteria.push(criterionCategory.id);
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;
          this.filterData.criteriaLength++;
          this.filterData.criteria.push(criterion.id);
        });
      })
    } else {
      this.addLockedCriteria(userView); 
      this.filterData.products = [...userView.productFilter];
      this.questionnaireStructure.products.forEach(product => {
        if (product.state == "Live") {
          if (this.filterData.products.includes(product.id)) {
            this.filterData.productsLength++;
          }
          this.filterData.productsCount++
        }
      });
      this.questionnaireStructure.criterionCategories.forEach(criterionCategory => {
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;         
          if (userView.criterionFilter.includes(criterionCategory.id) && userView.criterionFilter.includes(criterion.id)) {
            this.filterData.criteriaLength++;
          }
        });
      })
      this.filterData.criteria = userView.criterionFilter
    }
    this.filterDataReady = true;
  }

  addLockedCriteria(userView: IUserComparisonView){
    if(this.projectDataRestrictions != undefined){
      if(this.projectDataRestrictions['questionnaireFilteringCriterionCategory']){
        this.projectDataRestrictions['questionnaireFilteringCriterionCategory'].forEach(criterionCategory =>{
          if(!userView.criterionFilter.includes(criterionCategory)){
            userView.criterionFilter.push(criterionCategory);
            console.log("Criterion category added");
          }
        });
      }

      if(this.projectDataRestrictions['questionnaireFilteringCriterion']){
        this.projectDataRestrictions['questionnaireFilteringCriterion'].forEach(criterion =>{
          if(!userView.criterionFilter.includes(criterion)){
            userView.criterionFilter.push(criterion);
            console.log("Criterion added");
          }
        });
      }
    }    
  }

  validateUnigueName(updates: boolean = false){
    const name: string= this.name.value;
    const viewId = updates? this.userQuestionnaireView._id: "emptyId";
    const productType = this.questionnaireStructure._id;
    const isUnique = this.questionnaireComponent.questionnaireMenu.isUniqueViewName(viewId, productType, name.trim());
    if (isUnique) {
      this.saveViewForm.controls['name'].setErrors(null);
    } else {
      this.saveViewForm.controls['name'].setErrors({'isNotUnique': true});
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { IProject } from 'core-client/core/interfaces/project.interface';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { IQuestionnaireStructure } from 'src/app/models/questionnaireStructure.model';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getRouteNameByIdentificator } from "../../../../../../services/helper.service";
import { SettingsComponent } from '../../../settings.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-settings-role-create',
  templateUrl: './project-settings-role-create.component.html',
  styleUrls: ['./project-settings-role-create.component.scss']
})
export class ProjectSettingsRoleCreateComponent implements OnInit {

  projectDataRestrictions: IProject['dataRestrictionById'];
  projectRouteRestrictions: IProject['routeRestrictions'] = {
    comparison: true,
    questionnaire: true
  };
  routeAvailability: IProject['routeRestrictions'];
  comparisonStructureList: IComparisonStructure[];
  feedbackStructureList: IComparisonStructure[];
  questionnaireStructureList: IQuestionnaireStructure[];
  getRouteName = getRouteNameByIdentificator

  roleForm: FormGroup;
  objectKeys = Object.keys;

  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    public settingsComponent: SettingsComponent,
    private ar: ActivatedRoute,
    private router: Router
  ) {
    this.http.post(`/core/project/projectDataRestrictions`, {}).subscribe((data: any) => {
      this.projectDataRestrictions = data.dataRestrictionById
      this.routeAvailability = data.routeAvailability !== undefined ? data.routeAvailability : {};
    })
    this.http.post(`/app/comparisonStructure/list`, {}).subscribe((data: any) => {
      this.comparisonStructureList = data
    })
    this.http.post(`/app/questionnaireStructure/list`, {}).subscribe((data: any) => {
      this.questionnaireStructureList = data
    })    
    this.http.post(`/app/feedbackStructure/list`, {}).subscribe((data: any) => {
      this.feedbackStructureList = data
    })
  }

  ngOnInit() {
    this.roleForm = this.fb.group({
      'name': ['', [
        Validators.required
        ]
      ]
    });
  }

  get name() { return this.roleForm.get('name') }

  updateRestrictions(event, id, type){
    if(!this.projectDataRestrictions[type]) {
      this.projectDataRestrictions[type] = []
    }
    if (!event.target.checked) {
      this.projectDataRestrictions[type].push(id);
    }else{
      for( var i = 0; i < this.projectDataRestrictions[type].length; i++){ 
        if ( this.projectDataRestrictions[type][i] === id) {
          this.projectDataRestrictions[type].splice(i, 1); 
          i--;
        }
     }
    }
  }

  checkControl(id, type){
    if(!this.projectDataRestrictions[type]){
      return true
    }
    return !this.projectDataRestrictions[type].includes(id)
  }

  updatePath(event, type){
    this.projectRouteRestrictions[type] = event.target.checked ? true : false;
    console.log(this.projectRouteRestrictions);
  }
  checkPath(type){
    return this.projectRouteRestrictions[type];
  }

  addRole(){
    this.http.post(`/core/role/add`, {
      name: this.name.value,
      routeRestrictions: this.projectRouteRestrictions,
      dataRestrictionById: this.projectDataRestrictions
    }).subscribe((data: any) => {
      this.router.navigate(['/p/'+this.ar.snapshot.paramMap.get("projectId")+'/settings/project/roles/'])
    })
  }

  scrollTo(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }
}

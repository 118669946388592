import { Injectable } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IRole } from './interfaces/role.interface';
import { IProject } from './interfaces/project.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projectId: Observable<string>;
  userRole: IRole;

  constructor(
    private ar: ActivatedRoute,
    private http: HttpClient
  ) { }

  public createProject(projectName: string): Observable<IProject | any> {
    return this.http.post('/core/project/createProject', { projectName }).pipe(
      map((response: IProject) => { 
        return response;
      })
    )
  }

  public getProjectRole(): Observable<IRole | any> {
    return this.http.post('/core/project/getProjectRole', {}).pipe(
      map((response: IRole) => { 
        console.log(response);
        return response;
      })
    )
  }

}

import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
declare var jsPDF: any;
import html2canvas from "html2canvas";
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

export enum PdfType {
  comparison,
  questionnaire
}

@Component({
  selector: 'c-and-q-pdf-maker',
  templateUrl: './c-and-q-pdf-maker.component.html',
  styleUrls: ['./c-and-q-pdf-maker.component.scss']
})
export class CAndQPdfMakerComponent implements OnInit {
  static staticCAndQPdfMaker : CAndQPdfMakerComponent;
  logoImage : string = "/assets/images/eucs.svg";
  headerText : string = "";
  pdfTypeForTemplate : string = "";
  private date: string = formatDate(new Date(), "dd. MM. yyyy", "en");
  active: boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ar : ActivatedRoute,
    private http : HttpClient
  ) {
    CAndQPdfMakerComponent.staticCAndQPdfMaker = this;
  }

  @ViewChild('pdfBody', { static: true }) pdfBody: ElementRef;
  @ViewChildren('pdfHeader', { read: ElementRef }) pdfHeader: QueryList<ElementRef>;
  @ViewChildren('page', { read: ElementRef }) pages: ElementRef[];

  @ViewChild('pageContent', { static: false}) pageContent: ElementRef;

  ngOnInit() {
    this.http.post("/core/project/userProjects", {}).subscribe((projects: any) => {
      this.logoImage = "/assets/images/"+projects[0].project+".png";
    });

  }

  public attach(content: ElementRef, pdfType : PdfType) {
    this.active = true;
    if(pdfType == PdfType.comparison){
      this.pdfTypeForTemplate = "comparison";
    }
    else{
      this.pdfTypeForTemplate = "questionnaire";
    }
    this.cdRef.detectChanges();
    
    if(pdfType == PdfType.comparison){
      var allTableRows = [].slice.call(content.nativeElement.children).filter(x=>x.style.display != "none").sort(function (a, b) {
        return +a.style.order - +b.style.order;
      });
    }
    else{
      var allTableRows = [].slice.call(content.nativeElement.children).filter(x=>x.style.display != "none").sort(function (a, b) {
        return +b.style.order - +a.style.order;
      });
    }      

    allTableRows.forEach((row)=>{
      this.pageContent.nativeElement.insertAdjacentHTML("beforeend",row.outerHTML)
    })
    
  }

  public detach() {
    this.active = false;
  }
}

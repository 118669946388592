import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRole } from 'core-client/core/interfaces/role.interface';
import { IProject } from 'core-client/core/interfaces/project.interface';
import { SettingsComponent } from '../../settings.component';
import { IUser } from 'core-client/core/interfaces/authentication.interface';

@Component({
  selector: 'app-project-settings-users',
  templateUrl: './project-settings-users.component.html',
  styleUrls: ['./project-settings-users.component.scss']
})
export class ProjectSettingsUsersComponent implements OnInit {

  userList: IProject['userList'] | { user: string | IUser, role: string | IRole }[] ;
  unfilteredUserList : IProject['userList'];
  roleList: IRole[];
  searchBoxactive : boolean = false;

  constructor(
    private http: HttpClient,
    public settingsComponent: SettingsComponent
  ) {
    this.http.post(`/app/settings/usersAndRoles`, {}).subscribe((data: any) => {
      this.userList = data.userList.userList;
      this.unfilteredUserList = data.userList.userList;
      console.log(this.userList)
      this.roleList = data.roleList;
    })
  }

  ngOnInit() {
  }

  filter(value: string)  {
    const filterValue = value.toLowerCase().trim();
    this.userList = this.unfilteredUserList.filter(option => option.user['displayName'].toLowerCase().includes(filterValue)||option.user['email'].toLowerCase().includes(filterValue));
  }

  roleChanged(role, user) {
    let userId = user._id === undefined ? user : user._id;
    this.http.post(`/app/settings/userRoleUpdate`, { role: role, user: userId }).subscribe((data: any) => {
      user.changed=true;
    })
  }

}

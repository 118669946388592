import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from 'core-client/core/core.module';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AuthComponent } from './components/auth/auth.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AnalyticsMenuComponent } from './components/analytics/analytics-menu/analytics-menu.component';
import { ComparisonComponent } from './components/analytics/comparison/comparison.component';
import { ComparisonMenuComponent } from './components/analytics/comparison/comparison-menu/comparison-menu.component';
import { ComparisonTableComponent } from './components/analytics/comparison/comparison-table/comparison-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { QuestionnaireComponent } from './components/analytics/questionnaire/questionnaire.component';
import { SettingsComponent } from './components/analytics/settings/settings.component';
import { QuestionnaireMenuComponent } from './components/analytics/questionnaire/questionnaire-menu/questionnaire-menu.component';
import { QuestionnaireQuestionsComponent } from './components/analytics/questionnaire/questionnaire-questions/questionnaire-questions.component';
import { SettingsMenuComponent } from './components/analytics/settings/settings-menu/settings-menu.component';
import { ProjectSettingsComponent } from './components/analytics/settings/project-settings/project-settings.component';
import { ProjectSettingsRestrictionsComponent } from './components/analytics/settings/project-settings/project-settings-restrictions/project-settings-restrictions.component';
import { ProjectSettingsUsersComponent } from './components/analytics/settings/project-settings/project-settings-users/project-settings-users.component';
import { ProjectSettingsRolesComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-roles.component';
import { ProjectSettingsRoleDetailComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-role-detail/project-settings-role-detail.component';
import { ProjectSettingsRoleCreateComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-role-create/project-settings-role-create.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CAndQPdfMakerComponent } from './components/utilities/c-and-q-pdf-maker/c-and-q-pdf-maker.component';
import { LoaderComponent } from './components/utilities/loader/loader.component';
import { ApiLoginComponent } from './components/auth/api-login/api-login.component';
import { KapitolLoginComponent } from './components/auth/kapitol-login/kapitol-login.component';
import { KapitolAccessComponent } from './components/auth/kapitol-login/kapitol-access/kapitol-access.component';
import { FincentrumLoginComponent } from './components/auth/fincentrum-login/fincentrum-login.component';
import { FincentrumAccessComponent } from './components/auth/fincentrum-login/fincentrum-access/fincentrum-access.component';
import { FeedbackComponent } from './components/analytics/feedback/feedback.component';
import { FeedbackMenuComponent } from './components/analytics/feedback/feedback-menu/feedback-menu.component';
import { FeedbackTableComponent } from './components/analytics/feedback/feedback-table/feedback-table.component';
import { FeedbackCriteriaComponent } from './components/analytics/feedback/feedback-criteria/feedback-criteria.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeedbackNotificationComponent } from './components/analytics/feedback-notification/feedback-notification.component';
import { SwisslifeLoginComponent } from './components/auth/swisslife-login/swisslife-login.component';
import { SwisslifeAccessComponent } from './components/auth/swisslife-login/swisslife-access/swisslife-access.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    AnalyticsComponent,
    AnalyticsMenuComponent,
    ComparisonComponent,
    ComparisonMenuComponent,
    ComparisonTableComponent,
    ProjectCreateComponent,
    QuestionnaireComponent,
    SettingsComponent,
    QuestionnaireMenuComponent,
    QuestionnaireQuestionsComponent,
    SettingsMenuComponent,
    ProjectSettingsComponent,
    ProjectSettingsRestrictionsComponent,
    ProjectSettingsUsersComponent,
    ProjectSettingsRolesComponent,
    ProjectSettingsRoleDetailComponent,
    ProjectSettingsRoleCreateComponent,
    CAndQPdfMakerComponent,
    LoaderComponent,
    ApiLoginComponent,
    KapitolLoginComponent,
    KapitolAccessComponent,
    FincentrumLoginComponent,
    FincentrumAccessComponent,
    FeedbackComponent,
    FeedbackMenuComponent,
    FeedbackTableComponent,
    FeedbackCriteriaComponent,
    FeedbackNotificationComponent,
    SwisslifeLoginComponent,
    SwisslifeAccessComponent
    ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'core-client/theme/theme.service';
import { ActivatedRoute } from '@angular/router';
import { Theme } from 'core-client/theme/theme';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: []
})
export class AnalyticsComponent implements OnInit {

  sidenavOpen: boolean = true;

  constructor(
    private themeService : ThemeService,
    private ar : ActivatedRoute,
    private http: HttpClient
  ) {  }

  ngOnInit() {
    let projectId : string = "eucs"
    if(this.ar.snapshot.children.length > 0){
      projectId = this.ar.snapshot.children[0].paramMap.get('projectId');
    }
    const theme : Theme = this.themeService.getAvailableThemes().find(t=>t.name == projectId);
    if(theme){
      this.themeService.setActiveTheme(theme);
    }
  }

  toggleMenu(){
    this.sidenavOpen = !this.sidenavOpen;
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasAnyProjectGuard implements CanActivate {
  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.http.post('/core/project/userProjects',{}).pipe(
        map((response: any) => {
          if (response.length > 0) {
            return true;
          }
          return false;
        }),
        catchError(err => {
          console.log(err);
          return of(false);
        }),
        tap((hasProject: boolean) => {
          if(!hasProject){
            this.router.navigate([`/createProject`]);
          }
        })
      );
  }
  
}

import { Component, OnInit } from '@angular/core';
import { SettingsComponent } from '../../settings.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { IQuestionnaireStructure } from 'src/app/models/questionnaireStructure.model';
import { HttpClient } from '@angular/common/http';
import { IProject } from 'core-client/core/interfaces/project.interface';

@Component({
  selector: 'app-project-settings-restrictions',
  templateUrl: './project-settings-restrictions.component.html',
  styleUrls: ['./project-settings-restrictions.component.scss']
})
export class ProjectSettingsRestrictionsComponent implements OnInit {

  projectDataRestrictions: IProject['dataRestrictionById'];
  routeAvailability: IProject['routeRestrictions'];
  comparisonStructureList: IComparisonStructure[];
  feedbackStructureList: IComparisonStructure[];
  questionnaireStructureList: IQuestionnaireStructure[];
  comparisonOrder: any[] = [];
  questionnaireOrder: any[] = [];
  changesInfoText: string = "";

  constructor(
    public settingsComponent: SettingsComponent,
    private http: HttpClient
  ) { 
    this.http.post(`/core/project/projectDataRestrictions`, {}).subscribe((data: any) => {
      this.projectDataRestrictions = data.dataRestrictionById == undefined ? {} : data.dataRestrictionById;
      this.routeAvailability = data.routeAvailability !== undefined ? data.routeAvailability : {};
    })
    this.http.post(`/app/comparisonStructure/list`, {}).subscribe((data: any) => {
      console.log(data);
      this.comparisonStructureList = data
    })
    this.http.post(`/app/questionnaireStructure/list`, {}).subscribe((data: any) => {
      this.questionnaireStructureList = data
    })    
    this.http.post(`/app/feedbackStructure/list`, {}).subscribe((data: any) => {
      this.feedbackStructureList = data
    })
  }

  ngOnInit() {
  }

  updateRestrictions(event, id, type){
    if(!this.projectDataRestrictions[type]) {
      this.projectDataRestrictions[type] = []
    }
    //diff
    if (!event.target.checked) {
      this.projectDataRestrictions[type].push(id);
    }else{
      for( var i = 0; i < this.projectDataRestrictions[type].length; i++){ 
        if ( this.projectDataRestrictions[type][i] === id) {
          this.projectDataRestrictions[type].splice(i, 1); 
          i--;
        }
     }
    }
    this.changesInfoText = "Máte neuložené změny!";
  }

  updateFilteringRestrictions(event, id, type){
    if(!this.projectDataRestrictions[type]) {
      this.projectDataRestrictions[type] = []
    }
    //diff
    if (event.target.checked) {
      this.projectDataRestrictions[type].push(id);
    }else{
      for( var i = 0; i < this.projectDataRestrictions[type].length; i++){ 
        if ( this.projectDataRestrictions[type][i] === id) {
          this.projectDataRestrictions[type].splice(i, 1); 
          i--;
        }
     }
    }
    this.changesInfoText = "Máte neuložené změny!";
  }

  checkControl(id, type){
    if(!this.projectDataRestrictions[type]){
      return true
    }
    return !this.projectDataRestrictions[type].includes(id)
  }

  saveRestrictions(){
    for (let i = 0; i < this.comparisonStructureList.length; i++) {
      const productType = this.comparisonStructureList[i];
      for (let j = 0; j < productType.products.length; j++) {
        const product = productType.products[j];
        this.comparisonOrder.push({
          id: product.id,
          orderValue: product.defaultOrder
        })
      }
      for (let j = 0; j < productType.criterionCategories.length; j++) {
        const criteriaCategory = productType.criterionCategories[j];
        this.comparisonOrder.push({
          id: criteriaCategory.id,
          orderValue: criteriaCategory.defaultOrder
        })
        for (let k = 0; k < criteriaCategory.criteria.length; k++) {
          const criterion = criteriaCategory.criteria[k];
          this.comparisonOrder.push({
            id: criterion.id,
            orderValue: criterion.defaultOrder
          })
        }
      }
    }
    for (let i = 0; i < this.questionnaireStructureList.length; i++) {
      const productType = this.questionnaireStructureList[i];
      for (let j = 0; j < productType.products.length; j++) {
        const product = productType.products[j];
        this.questionnaireOrder.push({
          id: product.id,
          orderValue: product.defaultOrder
        })
      }
      for (let j = 0; j < productType.criterionCategories.length; j++) {
        const criteriaCategory = productType.criterionCategories[j];
        this.questionnaireOrder.push({
          id: criteriaCategory.id,
          orderValue: criteriaCategory.defaultOrder
        })
        for (let k = 0; k < criteriaCategory.criteria.length; k++) {
          const criterion = criteriaCategory.criteria[k];
          this.questionnaireOrder.push({
            id: criterion.id,
            orderValue: criterion.defaultOrder
          })
        }
      }
    }
    this.http.post(`/app/settings/updateProjectRestrictions`, { 
      dataRestrictionById: this.projectDataRestrictions, 
      comparisonOrder: this.comparisonOrder, 
      questionnaireOrder: this.questionnaireOrder
    }).subscribe((data) => {
      console.log(data);
      this.changesInfoText = "Všechny změny uloženy";
    })
  }

  drop(event: CdkDragDrop<string[]>, products) {
    console.log(event);
    console.log(products);
    products.splice(event.currentIndex, 0, products.splice(event.previousIndex, 1)[0]);
    for (let i = 0; i < products.length; i++) {
      const item = products[i];
      item.defaultOrder = i;
    }
    
    this.changesInfoText = "Máte neuložené změny!";
  }
  scrollTo(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kapitol-login',
  templateUrl: './kapitol-login.component.html',
  styleUrls: ['./kapitol-login.component.scss']
})
export class KapitolLoginComponent implements OnInit {

  constructor() { 
    window.location.href = "https://auth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/auth?scope=openid&response_type=code&redirect_uri=https://srovnavaczivot.kapitol.cz/auth/sso/kapitol/access&client_id=eucs"
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AuthComponent } from './components/auth/auth.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ApiLoginComponent } from './components/auth/api-login/api-login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { AuthGuard } from 'core-client/core/guards/auth.guard';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ComparisonComponent } from './components/analytics/comparison/comparison.component';
import { ComparisonTableComponent } from './components/analytics/comparison/comparison-table/comparison-table.component';
import { ProjectGuard } from 'core-client/core/guards/project.guard';
import { HasAnyProjectGuard } from 'core-client/core/guards/has-any-project.guard';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { QuestionnaireComponent } from './components/analytics/questionnaire/questionnaire.component';
import { QuestionnaireQuestionsComponent } from './components/analytics/questionnaire/questionnaire-questions/questionnaire-questions.component';
import { SettingsComponent } from './components/analytics/settings/settings.component';
import { ProjectSettingsComponent } from './components/analytics/settings/project-settings/project-settings.component';
import { ProjectSettingsRestrictionsComponent } from './components/analytics/settings/project-settings/project-settings-restrictions/project-settings-restrictions.component';
import { ProjectSettingsUsersComponent } from './components/analytics/settings/project-settings/project-settings-users/project-settings-users.component';
import { ProjectSettingsRolesComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-roles.component';
import { ProjectSettingsRoleDetailComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-role-detail/project-settings-role-detail.component';
import { ProjectSettingsRoleCreateComponent } from './components/analytics/settings/project-settings/project-settings-roles/project-settings-role-create/project-settings-role-create.component';
import { KapitolLoginComponent } from './components/auth/kapitol-login/kapitol-login.component';
import { KapitolAccessComponent } from './components/auth/kapitol-login/kapitol-access/kapitol-access.component';
import { FincentrumLoginComponent } from './components/auth/fincentrum-login/fincentrum-login.component';
import { FincentrumAccessComponent } from './components/auth/fincentrum-login/fincentrum-access/fincentrum-access.component';
import { FeedbackComponent } from './components/analytics/feedback/feedback.component';
import { FeedbackTableComponent } from './components/analytics/feedback/feedback-table/feedback-table.component';
import { FeedbackCriteriaComponent } from './components/analytics/feedback/feedback-criteria/feedback-criteria.component';
import { ComparisonGuard } from './guards/comparison.guard';
import { QuestionnaireGuard } from './guards/questionnaire.guard';
import { FeedbackGuard } from './guards/feedback.guard';
import { SettingsGuard } from './guards/settings.guard';
import { FeedbackNotificationComponent } from './components/analytics/feedback-notification/feedback-notification.component';
import { SwisslifeLoginComponent } from './components/auth/swisslife-login/swisslife-login.component';
import { SwisslifeAccessComponent } from './components/auth/swisslife-login/swisslife-access/swisslife-access.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  {
    path: 'p/:projectId', component: AnalyticsComponent, children: [
      {
        path: 't', component: ComparisonComponent, children: [
          { path: ':productTypeId', redirectTo: ':productTypeId/all' },
          { path: ':productTypeId/:userView', component: ComparisonTableComponent }
        ], canActivate: [ComparisonGuard]
      },
      {
        path: 'q', component: QuestionnaireComponent, children: [
          { path: ':questionnaireProductTypeId', redirectTo: ':questionnaireProductTypeId/all' },
          { path: ':questionnaireProductTypeId/:questionnaireUserView', component: QuestionnaireQuestionsComponent }
        ], canActivate: [QuestionnaireGuard]
      },{
        path: 'i', component: FeedbackComponent, children: [
          { path: ':feedbackProductTypeId/criteria', component: FeedbackCriteriaComponent },
          { path: ':feedbackProductTypeId', redirectTo: ':feedbackProductTypeId/first' },
          { path: ':feedbackProductTypeId/:feedbackProductId', component: FeedbackTableComponent }
        ], canActivate: [FeedbackGuard]
      },{
        path: 'i-n', component: FeedbackNotificationComponent, children: [
        ], canActivate: [FeedbackGuard]
      },
      {
        path: 'settings', component: SettingsComponent, children: [
          {
            path: 'project', component: ProjectSettingsComponent, children: [
              { path: '', component: ProjectSettingsRestrictionsComponent },
              { path: 'users', component: ProjectSettingsUsersComponent },
              { path: 'roles', component: ProjectSettingsRolesComponent },
              { path: 'roles/detail/:roleId', component: ProjectSettingsRoleDetailComponent },
              { path: 'roles/create', component: ProjectSettingsRoleCreateComponent }
            ]
          }
        ], canActivate: [SettingsGuard]
      },
    ], canActivate: [AuthGuard, HasAnyProjectGuard, ProjectGuard]
  },
  //{ path: 'createProject', component: ProjectCreateComponent, canActivate: [AuthGuard] },
  {
    path: 'auth', component: AuthComponent, children: [
      { path: 'sso/kapitol', component: KapitolLoginComponent},
      { path: 'sso/kapitol/access', component: KapitolAccessComponent},
      { path: 'sso/fincentrum', component: FincentrumLoginComponent},
      { path: 'sso/fincentrum/access', component: FincentrumAccessComponent},
      { path: 'sso/swisslifeselect', component: FincentrumLoginComponent},
      { path: 'sso/swisslifeselect/access', component: FincentrumAccessComponent},
      { path: 'sso/swisslife', component: SwisslifeLoginComponent},
      { path: 'sso/swisslife/access', component: SwisslifeAccessComponent},
      { path: '', component: LoginComponent },
      //{ path: 'register', component: RegisterComponent },
    { path: 'tl/:apiLoginProjectId/:apiLoginEmail', component: ApiLoginComponent },
    ], canActivate: []
  },
  { path: '', redirectTo: '/auth', pathMatch: 'full'},
  //{ path: '', component: LandingPageComponent }
  { path: '**', redirectTo: '/auth', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ITokenPayload, ITokenResponse } from './interfaces/authentication.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../src/environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private token: string;
  private prePath: string = environment.url;

  constructor(private http: HttpClient, private router: Router) { }

  public saveToken(token: string): void {
    localStorage.setItem('access_token', token);
    this.token = token;
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('access_token');
    }
    return this.token;
  }

  public getTokenPayload(): ITokenPayload {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];

      //revize; možná použít jsnowebtoken decode
      try {
        var base64Url = payload;
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      }
      catch(err){
        console.log(err);
      }
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getTokenPayload();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('access_token');
    this.router.navigateByUrl('/');
  }

  public registerUserWithEmailAndPassword(email: string, password: string, displayName: string = ""): Observable<ITokenResponse | any> {
    return this.http.post(this.prePath+'/core/auth/register', { email, password, displayName }).pipe(
      map((data: ITokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data
      })
    )
  }

  public loginUserWithEmailAndPassword(email: string, password: string): Observable<ITokenResponse | any> {
    return this.http.post(this.prePath+'/core/auth/login', { email, password }).pipe(
      map((data: ITokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data
      })
    )
  }

  //this.http.get(``, { headers: { Authorization: `Bearer ${this.getToken()}` }});
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'core-client/core/authentication.service';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-api-login',
  templateUrl: './api-login.component.html',
  styleUrls: ['./api-login.component.scss']
})
export class ApiLoginComponent implements OnInit {

  private url_email: Observable<string>;
  private url_projectId: Observable<string>;
  public statusText: string = "Inicializace";

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private auth: AuthenticationService,
    private http: HttpClient,
  ) {
    this.url_email = this.ar.paramMap.pipe(
      map(params => {
        return params.get('apiLoginEmail')
      })
    )
    this.url_projectId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('apiLoginProjectId')
      })
    )
    this.url_email.pipe(
      switchMap(email => {
        return this.url_projectId.pipe(
          switchMap(projectId => {
            return this.auth.loginUserWithEmailAndPassword(email, email).pipe(
              map(() => {
                return projectId
              })
            )
          })
        )
      })
    ).subscribe(projectId => {
      this.statusText = 'prihlasen';
      this.http.post('/app/settings/userRestrictions',{projectId: projectId}).subscribe((data: any) => {
        let path = '/p/' + projectId;
        if(data.comparisonPath){
          path +=  "/t/" + data.comparisonPath;
          this.router.navigate([path]);
        }else if(data.questionnairePath){
          path +=  "/q/" + data.questionnairePath;
          this.router.navigate([path]);
        }else{
          path +=  "/i/" + data.feedbackPath;
          this.router.navigate([path]);
        }
      })
    })
  }

  ngOnInit() {

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { IComparisonStructure } from '../models/comparisonStructure.model';
import { IUserComparisonView } from '../models/userComparisonView.model';
import { IAnalysis } from '../models/analysis.model';
import { IProductType } from '../models/productType.model';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {

  productTypeId: Observable<string>;
  userViewId: Observable<string>;
  comparisonStructure: Observable<IComparisonStructure>;
  userComparisonView: Observable<IUserComparisonView>;

  constructor(
    private http: HttpClient,
    private ar: ActivatedRoute,
  ) {
    this.productTypeId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('productTypeId');
      })
    )
    this.userViewId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('userView');
      })
    )
    this.comparisonStructure = this.productTypeId.pipe(
      switchMap(productTypeId => {
        return this.http.post<IComparisonStructure>(`/app/comparisonStructure/get/${productTypeId}`, {});
      })
    )
    this.userComparisonView = this.userViewId.pipe(
      take(1),
      switchMap(userViewId => {
        if (userViewId == "all") {
          return of<IUserComparisonView>({
            _id: "all",
            name: "Základní pohled",
            productFilter: [],
            criterionFilter: []
          })
        } else if (userViewId == "rzp") {
          return of<IUserComparisonView>({
            _id: "rzp",
            name: "Rizikové životní pojištění",
            productFilter: [],
            criterionFilter: []
          })
        } else if (userViewId == "izp") {
          return of<IUserComparisonView>({
            _id: "izp",
            name: "Investiční životní pojištění",
            productFilter: [],
            criterionFilter: []
          })
        }
        return this.http.post<IUserComparisonView>(`/app/userComparisonView/get/${userViewId}`, {});
      })
    )

  }

  listAnalyses(historicalVersionArray, includehistoricalVersions: boolean = false) {
    return this.comparisonStructure.pipe(
      switchMap((comparisonStructure: IComparisonStructure) => {
        return this.userComparisonView.pipe(
          switchMap((userComparisonView: IUserComparisonView) => {
            let tempArray = [];
            comparisonStructure.products.forEach((product: IComparisonStructure['products'][0]) => {
              if (product.productVersions !== undefined) {
                product.productVersions.forEach((productVersion: IComparisonStructure['products'][0]['productVersions'][0]) => {
                  if (includehistoricalVersions) {
                    if (productVersion.state == 'Aktuální' || productVersion.state == 'Neaktuální' || productVersion.state == "Historická") {
                      tempArray.push(productVersion.id);
                    }
                  }
                  else {
                    if (productVersion.state == 'Aktuální' || productVersion.state == 'Neaktuální') {
                      tempArray.push(productVersion.id);
                    }
                  }
                })
              }
            })
            tempArray.push(...historicalVersionArray);
            return this.http.post('/app/analysis/listByArray', { productVersionList: tempArray }).pipe(
              map((analyses: IAnalysis[]) => {
                let tempCriteria: string[] = [];
                comparisonStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
                  criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
                    tempCriteria.push(criterion.id);
                  });
                })
                const filteredAnalyses = analyses.filter((a: any) => tempCriteria.includes(a.criterion));
                return { filteredAnalyses, userComparisonView, comparisonStructure }
              })
            );
          })
        )
      })
    ).pipe(
      take(1),
      map(data => {
        let analysesObject = this.createTableObject(data.filteredAnalyses, data.comparisonStructure);
        return { analyses: analysesObject, userComparisonView: data.userComparisonView, comparisonStructure: data.comparisonStructure };
      })
    )
  }

  createTableObject(analyses: IAnalysis[], comparisonStructure: IComparisonStructure) {
    let tableObject = {};
    let orderObject = {};

    comparisonStructure.products.forEach((product: IComparisonStructure['products'][0]) => {
      orderObject[product.id] = product.defaultOrder;
    });

    comparisonStructure.criterionCategories.forEach((criterionCategory: IComparisonStructure['criterionCategories'][0]) => {
      orderObject[criterionCategory.id] = criterionCategory.defaultOrder;
      criterionCategory.criteria.forEach((criterion: IComparisonStructure['criterionCategories'][0]['criteria'][0]) => {
        orderObject[criterion.id] = criterion.defaultOrder;
      });
    })

    analyses.forEach((analysis: IAnalysis | any) => {
      // Pokud se objevila chyba v Airtable neber vpotaz
      if (analysis.criterionCategory === null || analysis.criterion === null || analysis.product === null) {
        return;
      }

      if (tableObject[analysis.criterionCategory] === undefined) {
        tableObject[analysis.criterionCategory] = {
          name: analysis.criterionCategoryName,
          description: analysis.criterionCategoryDescription,
          defaultOrder: orderObject[analysis.criterionCategory] !== undefined ? orderObject[analysis.criterionCategory] * 10000 : analysis.criterionCategoryDefaultOrder * 10000
        }
      }

      if (tableObject[analysis.criterionCategory]['productVersions'] === undefined) {
        tableObject[analysis.criterionCategory]['productVersions'] = {}

        tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] = {
          data: false
        }
      }

      if (tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] === undefined || !tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion].data) {
        tableObject[analysis.criterionCategory]['productVersions'][analysis.productVersion] = {
          productName: analysis.productName,
          defaultOrder: orderObject[analysis.product] != undefined ? (orderObject[analysis.product] * 1000 + (analysis.productVersionDefaultOrder * 10)) : (analysis.productDefaultOrder * 1000 + (analysis.productVersionDefaultOrder * 10)),
          productId: analysis.product,
          state: analysis.versionState,
          effectiveDate: analysis.versionEffectiveDate,
          materials: analysis.materials
        }
      }

      if (tableObject[analysis.criterionCategory]['criteria'] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'] = {}
      }
      if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] = {
          name: analysis.criterionName,
          description: analysis.criterionDescription,
          analysisDescriptionLength: (analysis.subcriterion == "" ? analysis.moreInformation.length : 0),
          defaultOrder: tableObject[analysis.criterionCategory].defaultOrder + (orderObject[analysis.criterion] != undefined ? orderObject[analysis.criterion] : analysis.criterionDefaultOrder) * 100,
          redFilter: [],
          blueFilter: [],
          greenFilter: []
        }
      } else {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].analysisDescriptionLength += (analysis.subcriterion == "" ? analysis.moreInformation.length : 0);
      }
      if (analysis.subcriterion == "" && tableObject[analysis.criterionCategory]['criteria'][analysis.criterion] !== undefined) {
        if (analysis.backgroundColor == "Červená" || analysis.backgroundColor == "Bílá") {
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].greenFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].greenFilter.push(analysis.productVersion);
          }
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].blueFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].blueFilter.push(analysis.productVersion);
          }
        }
        if (analysis.backgroundColor == "Modrá") {
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].greenFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].greenFilter.push(analysis.productVersion);
          }
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].redFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].redFilter.push(analysis.productVersion);
          }
        }
        if (analysis.backgroundColor == "Zelená") {
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].blueFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].blueFilter.push(analysis.productVersion);
          }
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].redFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion].redFilter.push(analysis.productVersion);
          }
        }
      }
      if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'] = {}
      }

      if (analysis.subcriterion != "" && tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion] = {
          name: analysis.subcriterionName,
          analysisDescriptionLength: analysis.moreInformation.length,
          defaultOrder: analysis.subcriterionDefaultOrder + tableObject[analysis.criterionCategory].defaultOrder + (orderObject[analysis.criterion] != undefined ? orderObject[analysis.criterion] : analysis.criterionDefaultOrder) * 100,
          redFilter: [],
          blueFilter: [],
          greenFilter: []
        }
      } else if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion] !== undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].analysisDescriptionLength += (analysis.subcriterion != "" ? analysis.moreInformation.length : 0);
      }
      if (analysis.subcriterion != "" && tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion] !== undefined) {
        if (analysis.backgroundColor == "Červená" || analysis.backgroundColor == "Bílá") {
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].greenFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].greenFilter.push(analysis.productVersion);
          }
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].blueFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].blueFilter.push(analysis.productVersion);
          }
        }
        if (analysis.backgroundColor == "Modrá") {
          if (!tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].greenFilter.includes(analysis.productVersion)) {
            tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion].greenFilter.push(analysis.productVersion);
          }
        }
      }

      if (analysis.subcriterion != "" && tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'] = {}
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'][analysis.productVersion] = {
          data: false
        }
      }

      if (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'] === undefined) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'] = {}
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] = {
          data: false
        }
      }

      if (analysis.subcriterion == "" && (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] === undefined || !tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion].data)) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['productAnalyses'][analysis.productVersion] = {
          state: analysis.state,
          description: analysis.description,
          backgroundColor: analysis.backgroundColor,
          moreInformation: analysis.moreInformation,
          defaultOrder: orderObject[analysis.product] != undefined ? (orderObject[analysis.product] * 1000 + (analysis.productVersionDefaultOrder * 10)) : (analysis.productDefaultOrder * 1000 + (analysis.productVersionDefaultOrder * 10)),
        }
      }

      if (analysis.subcriterion != "" && (tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'][analysis.productVersion] === undefined || !tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'][analysis.productVersion].data)) {
        tableObject[analysis.criterionCategory]['criteria'][analysis.criterion]['subcriteria'][analysis.subcriterion]['productAnalyses'][analysis.productVersion] = {
          state: analysis.state,
          description: analysis.description,
          backgroundColor: analysis.backgroundColor,
          moreInformation: analysis.moreInformation,
          defaultOrder: orderObject[analysis.product] != undefined ? (orderObject[analysis.product] * 1000 + (analysis.productVersionDefaultOrder * 10)) : (analysis.productDefaultOrder * 1000 + (analysis.productVersionDefaultOrder * 10)),
        }
      }

    })
    console.time("checksum");
    for (var key in tableObject) {
      tableObject[key]['checksum'] = [];
      const productVersions = tableObject[key].productVersions
      for (var key2 in productVersions) {
        const productVersion = tableObject[key].productVersions[key2]
        if (productVersion.defaultOrder) {
          tableObject[key]['checksum'].push({
            position: productVersion.defaultOrder,
            state: productVersion.state,
            produtVersionId: key2
          })
        }
      }
      for (const criterionKey in tableObject[key].criteria) {
        if (tableObject[key].criteria.hasOwnProperty(criterionKey)) {
          const criterion = tableObject[key].criteria[criterionKey];

          if (tableObject[key]['checksum'].length != Object.keys(criterion.productAnalyses).length) {

            tableObject[key]['checksum'].forEach(productVersionChecksum => {
              if (!criterion.productAnalyses.hasOwnProperty(productVersionChecksum.produtVersionId)) {
                criterion.productAnalyses[productVersionChecksum.produtVersionId] = {
                  defaultOrder: productVersionChecksum.position,
                  description: "Není",
                  state: productVersionChecksum.state,
                  backgroundColor: "Bílá",
                  moreInformation: "Analýza neexistuje."
                }
              }
            })
          }

          for (const subcriterionKey in tableObject[key].criteria[criterionKey].subcriteria) {
            if (tableObject[key].criteria[criterionKey].subcriteria.hasOwnProperty(subcriterionKey)) {
              const subcriterion = tableObject[key].criteria[criterionKey].subcriteria[subcriterionKey];

              if (tableObject[key]['checksum'].length != Object.keys(subcriterion.productAnalyses).length) {

                tableObject[key]['checksum'].forEach(productVersionChecksum => {
                  if (!subcriterion.productAnalyses.hasOwnProperty(productVersionChecksum.produtVersionId)) {
                    subcriterion.productAnalyses[productVersionChecksum.produtVersionId] = {
                      defaultOrder: productVersionChecksum.position,
                      description: "Není",
                      state: productVersionChecksum.state,
                      backgroundColor: "Bílá",
                      moreInformation: "Analýza neexistuje."
                    }
                  }
                })
              }
            }
          }

        }
      }
    }
    console.timeEnd("checksum");
    console.log(tableObject)
    return tableObject;
  }

  createView = (userView: IUserComparisonView) => {
    return this.http.post(`/app/userComparisonView/add`, { userView });
  }
  updateView = (userView: IUserComparisonView) => {
    return this.http.post(`/app/userComparisonView/update/${userView._id}`, { userView });
  }
  deleteView = (userViewId: string) => {
    return this.http.post(`/app/userComparisonView/delete/${userViewId}`, {});
  }

  toggleFavourite = (userViewId: string, favouriteState) => {
    return this.http.post(`/app/userComparisonView/update/${userViewId}`, { userView: { favourite: !favouriteState } });
  }

}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthenticationService,
    public ar: ActivatedRoute,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken: string = this.auth.getToken();
    
    let projectId: string = undefined;
    if(this.ar.snapshot.children.length > 0){
      projectId = this.ar.snapshot.children[0].paramMap.get('projectId');
      
    }
    
    if (idToken) {
      
      if (projectId) {
        if(request.url == '/app/feedback/uploadFileForProductVersion'){  
          request.body.append('projectId',projectId);
          const cloned = request.clone({
            headers: request.headers.set("Authorization",
              `Bearer ${idToken}`),
            body: request.body
          });          
          return next.handle(cloned);
        }
        const cloned = request.clone({
          headers: request.headers.set("Authorization",
            `Bearer ${idToken}`),
          body: { ...request.body, projectId: projectId }
        });
        return next.handle(cloned);
      } else {
        const cloned = request.clone({
          headers: request.headers.set("Authorization",
            `Bearer ${idToken}`)
        });
        return next.handle(cloned);
      }

    } else {
      return next.handle(request);
    }
  }

}
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IQuestionnaireStructure } from '../models/questionnaireStructure.model';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { IAnalysis } from '../models/analysis.model';
import { IUserComparisonView } from '../models/userComparisonView.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  productTypeId: Observable<string>;
  userViewId: Observable<string>;
  questionnaireStructure: Observable<IQuestionnaireStructure>;
  userQuestionnaireView: Observable<IUserComparisonView>;

  constructor(
    private http: HttpClient,
    private ar: ActivatedRoute,
  ) {
    this.productTypeId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('questionnaireProductTypeId');
      })
    )
    this.userViewId = this.ar.paramMap.pipe(
      map(params => {
        return params.get('questionnaireUserView');
      })
    )
    this.questionnaireStructure = this.productTypeId.pipe(
      switchMap(questionnaireProductTypeId => {
        return this.http.post<IQuestionnaireStructure>(`/app/questionnaireStructure/get/${questionnaireProductTypeId}`, {});
      })
    )
    this.userQuestionnaireView = this.userViewId.pipe(
      take(1),
      switchMap(userViewId => {
        if (userViewId == "all") {
          return of<IUserComparisonView>({
            _id: "all",
            name: "Základní pohled",
            productFilter: [],
            criterionFilter: []
          })
        }
        return this.http.post<IUserComparisonView>(`/app/userQuestionnaireView/get/${userViewId}`, {});
      })
    )
  }

  listAnalyses(historicalVersionArray) {
    return this.questionnaireStructure.pipe(
      switchMap((questionnaireStructure: IQuestionnaireStructure) => {
        return this.userQuestionnaireView.pipe(
          switchMap((userQuestionnaireView: IUserComparisonView) => {
            let tempArray = [];
            questionnaireStructure.products.forEach((product: IQuestionnaireStructure['products'][0]) => {
              if (product.productVersions !== undefined) {
                product.productVersions.forEach((productVersion: IQuestionnaireStructure['products'][0]['productVersions'][0]) => {
                  if (productVersion.state == 'Aktuální' || productVersion.state == 'Neaktuální') {
                    tempArray.push(productVersion.id);
                  }
                })
              }
            })
            tempArray.push(...historicalVersionArray);
            return this.http.post('/app/analysis/questionnaire/listByArray', { productVersionList: tempArray }).pipe(
              map((analyses: IAnalysis[]) => {
                return { analyses, userQuestionnaireView, questionnaireStructure }
              })
            );
          })
        )
      })
    ).pipe(
      take(1),
      map(data => {
        let analysesObject = this.createTableObject(data.analyses, data.questionnaireStructure);
        return { analyses: analysesObject, userQuestionnaireView: data.userQuestionnaireView,  questionnaireStructure: data.questionnaireStructure };
      })
    )
  }

  createTableObject(analyses: IAnalysis[], questionnaireStructure: IQuestionnaireStructure) {
    let tableObject = {};
    let orderObject = {};
    questionnaireStructure.products.forEach(product => {
      orderObject[product.id] = product.defaultOrder;
    });
    questionnaireStructure.criterionCategories.forEach(criterionCategory => {
      orderObject[criterionCategory.id] = criterionCategory.defaultOrder;
      criterionCategory.criteria.forEach(criterion => {
        orderObject[criterion.id] = criterion.defaultOrder;
      });
    });
    analyses.forEach((analysis: IAnalysis | any) => {
      if (analysis.questionnaireCriterionCategory === null || analysis.criterion === null || analysis.product === null) {
        return;
      }
      if (tableObject[analysis.questionnaireCriterionCategory] === undefined) {
        tableObject[analysis.questionnaireCriterionCategory] = {
          defaultOrder: orderObject[analysis.questionnaireCriterionCategory] != undefined ? orderObject[analysis.questionnaireCriterionCategory] : analysis.criterionCategoryDefaultOrder,
          id: analysis.questionnaireCriterionCategory,
          name: analysis.questionnaireCriterionCategoryName
        }
      }

      if (tableObject[analysis.questionnaireCriterionCategory]['criteria'] === undefined) {
        tableObject[analysis.questionnaireCriterionCategory]['criteria'] = {}
      }
      if (tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion] === undefined) {
        tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion] = {
          name: analysis.criterionName,
          description: analysis.criterionDescription,
          defaultOrder: tableObject[analysis.questionnaireCriterionCategory].defaultOrder + (orderObject[analysis.criterion] != undefined ? orderObject[analysis.criterion] : analysis.criterionDefaultOrder * 10)
        }
      }

      if (tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'] === undefined) {
        tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'] = {}
        tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'][analysis.productVersion] = {
          data: false
        }
      }
      if (tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'][analysis.productVersion] === undefined || !tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'][analysis.productVersion].data) {
        tableObject[analysis.questionnaireCriterionCategory]['criteria'][analysis.criterion]['analyses'][analysis.productVersion] = {
          state: analysis.state,
          description: analysis.description,
          backgroundColor: analysis.backgroundColor,
          moreInformation: analysis.moreInformation,
          defaultOrder: orderObject[analysis.product] != undefined ? orderObject[analysis.product] : analysis.productDefaultOrder,
          answera: analysis.questionnaireAnswerA,
          answerb: analysis.questionnaireAnswerB,
          answerc: analysis.questionnaireAnswerC,
        }
      }
    });
    console.log(tableObject)
    return tableObject
  }

  createView = (userView: IUserComparisonView) => {
    console.log(userView);
    return this.http.post(`/app/userQuestionnaireView/add`, { userView });
  }
  updateView = (userView: IUserComparisonView) => {
    return this.http.post(`/app/userQuestionnaireView/update/${userView._id}`, { userView });
  }
  deleteView = (userViewId: string) => {
    return this.http.post(`/app/userQuestionnaireView/delete/${userViewId}`, { });
  }

  toggleFavourite = (userViewId: string, favouriteState) => {
    return this.http.post(`/app/userQuestionnaireView/update/${userViewId}`, { userView: { favourite: !favouriteState } });
  }

}

import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { IFeedbackNotification } from 'src/app/models/feedbackNotification.model';

@Component({
  selector: 'app-feedback-notification',
  templateUrl: './feedback-notification.component.html',
  styleUrls: ['./feedback-notification.component.scss'],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class FeedbackNotificationComponent implements OnInit {
  public loadingData: boolean = true;
  public notifications: IFeedbackNotification[] = null;

  constructor(
    private http: HttpClient
  ) { 
    this.http.post(`/app/feedbackNotification/list`, {
    }).subscribe((data: any) => { 
      this.notifications = data;
      this.loadingData = false;
    });
  }

  ngOnInit() {
  }

}

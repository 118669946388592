import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'core-client/core/authentication.service';


@Component({
  selector: 'app-fincentrum-access',
  templateUrl: './fincentrum-access.component.html',
  styleUrls: ['./fincentrum-access.component.scss']
})
export class FincentrumAccessComponent implements OnInit {

  code: Observable<string>;

  constructor(
    private http: HttpClient,
    private ar: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.code = this.ar.queryParamMap.pipe(
      map(params => {
        return params.get("code");
      })
    )
  }

  ngOnInit() {
    this.code.pipe(
      switchMap(code => {
        return this.http.post("/app/sso/fincentrum", { code });
      })
    ).subscribe((res: any) => {
      this.authService.saveToken(res.token);
      this.http.post("/core/project/userProjects", {}).subscribe((projects: any) => {
        let path = "/p/" + projects[0].project;
        this.http.post("/app/settings/userRestrictions", { projectId: projects[0].project }).subscribe((data: any) => {
          if(data.comparisonPath){
            path +=  "/t/" + data.comparisonPath;
            this.router.navigate([path]);
          }else if(data.questionnairePath){
            path +=  "/q/" + data.questionnairePath;
            this.router.navigate([path]);
          }else{
            path +=  "/i/" + data.feedbackPath;
            this.router.navigate([path]);
          }
        })
      })
    })
  }

}

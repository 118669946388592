import { Component, OnInit, OnDestroy, ElementRef, AfterContentInit, AfterViewChecked, ViewChild } from '@angular/core';
import { ThemeService } from 'core-client/theme/theme.service';
import { HttpClient } from '@angular/common/http';
import { ComparisonComponent } from '../comparison.component';
import { ComparisonService } from 'src/app/services/comparison.service';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { IUserComparisonView, IFilterData } from 'src/app/models/userComparisonView.model';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as moment from "moment";
import { AnalyticsComponent } from '../../analytics.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CAndQPdfMakerComponent, PdfType } from 'src/app/components/utilities/c-and-q-pdf-maker/c-and-q-pdf-maker.component';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-comparison-table',
  templateUrl: './comparison-table.component.html',
  styleUrls: ['./comparison-table.component.scss'],
  providers: [ComparisonService],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class ComparisonTableComponent implements OnInit, OnDestroy, AfterViewChecked {

  objectKeys = Object.keys;
  analyses: any;
  comparisonStructure: IComparisonStructure;
  userComparisonView: IUserComparisonView;
  urlChangeSubscription: Subscription;
  pdfmaker: CAndQPdfMakerComponent;
  pdfMode: boolean = false;

  filterProducts: boolean = false;
  filterCriteria: boolean = false;
  filterData: IFilterData = {
    products: [],
    productsCount: 0,
    productsLength: 0,
    criteria: [],
    criteriaCount: 0,
    criteriaLength: 0,
    historicalVersions: [],
    semafor: []
  }
  filterDataReady: boolean = false;
  loadingData: boolean = true;

  fullscreenMode: boolean = false;
  comparisonSideNavOpenState: boolean;

  saveViewForm: FormGroup;

  canShowPdfPreviw: boolean = false;
  minimalProductsLengthToShowPdfPrevie: number = 4;
  savePopup : boolean = false;

  constructor(
    public theme: ThemeService,
    private router: Router,
    private ar: ActivatedRoute,
    private http: HttpClient,
    public comparisonComponent: ComparisonComponent,
    private comparisonService: ComparisonService,
    private analyticsComponent: AnalyticsComponent,
    private elem: ElementRef,
    public fb: FormBuilder,
  ) {
    this.urlChangeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(e => {
      this.filterData.historicalVersions = [];
      this.filterData.semafor = [];
      this.loadingData = true;
      this.comparisonService.listAnalyses(this.filterData.historicalVersions, true).subscribe(data => {
        this.comparisonStructure = data.comparisonStructure;
        this.userComparisonView = data.userComparisonView;
        this.analyses = data.analyses;
        this.name.setValue(data.userComparisonView.name);
        this.userViewInitialize(this.userComparisonView);
        this.comparisonComponent.comparisonMenu.activateMenu(data.comparisonStructure._id);
        this.loadingData = false;
      })
    })
  }

  @ViewChild('comparisonTable', { static: false }) comparisonTable: ElementRef;

  ngAfterViewChecked() {
    if (this.filterDataReady) {
      console.log("toggle filter viewChecked")
      this.toggleByFilter()
      this.filterDataReady = false;
    }
  }
  ngOnInit() {
    this.saveViewForm = this.fb.group({
      'name': ["", [
        Validators.required
      ]
      ]
    });
    this.pdfmaker = CAndQPdfMakerComponent.staticCAndQPdfMaker;
    this.comparisonSideNavOpenState = this.comparisonComponent.sidenavOpen;
  }

  get name() { return this.saveViewForm.get('name') }

  enterPdfMode() {
    if (this.canShowPdfPreviw) {
      this.pdfMode = true;
      this.pdfmaker.attach(this.comparisonTable, PdfType.comparison);
    }
  }
  exitPdfMode() {
    this.pdfMode = false;
    this.pdfmaker.headerText = "";
    this.pdfmaker.detach();
  }

  showPdfPreview() {
    window.print()
  }

  createUserView() {
    this.validateUnigueName();
    if(this.saveViewForm.invalid){
      return;
    }
    this.savePopup = !this.savePopup;
    this.comparisonService.createView({
      name: this.name.value,
      productFilter: this.filterData.products,
      criterionFilter: this.filterData.criteria,
      productType: this.comparisonStructure._id,
      favourite: false
    }).subscribe((res: any) => {
      console.log(res);
      this.router.navigate([`../${res.data}`], { relativeTo: this.ar }).then(() => {
        this.comparisonComponent.comparisonMenu.reloadMenuData();
      })
    })
  }

  updateUserView() {
    this.validateUnigueName(true);
    if(this.saveViewForm.invalid){
      return;
    }
    this.savePopup = !this.savePopup;
    this.comparisonService.updateView({
      name: this.name.value,
      productFilter: this.filterData.products,
      criterionFilter: this.filterData.criteria,
      productType: this.comparisonStructure._id,
      _id: this.userComparisonView._id
    }).subscribe((res: any) => {
      console.log(res);
      this.comparisonComponent.comparisonMenu.reloadMenuData();
      this.userComparisonView.name = this.name.value;
    })
  }
  toggleFavourite() {
    this.comparisonService.toggleFavourite(this.userComparisonView._id, this.userComparisonView.favourite).subscribe((res: any) => {
      this.userComparisonView.favourite = !res.favourite;
      this.comparisonComponent.comparisonMenu.reloadMenuData();
    })
  }

  deleteUserView() {
    this.comparisonService.deleteView(this.userComparisonView._id).subscribe((res: any) => {
      console.log(res);
      this.router.navigate([`../all`], { relativeTo: this.ar }).then(() => {
        this.comparisonComponent.comparisonMenu.reloadMenuData();
      })
    })
  }

  ngOnDestroy() {
    this.urlChangeSubscription.unsubscribe();
    this.pdfmaker.detach();
  }

  dateToProductFormat(date) {
    return moment(date, 'YYYY-MM-DD').format('D. M. YYYY');
  }

  onNavigate(url) {
    window.open(url, "_blank");
  }

  fullScreen() {
    if (this.fullscreenMode) {
      this.fullscreenMode = false;
      this.comparisonComponent.sidenavOpen = this.comparisonSideNavOpenState;
      this.analyticsComponent.sidenavOpen = true;
    } else {
      this.comparisonSideNavOpenState = this.comparisonComponent.sidenavOpen;
      this.fullscreenMode = true;
      this.comparisonComponent.sidenavOpen = false;
      this.analyticsComponent.sidenavOpen = false;
    }
  }

  toggleByFilter(type = "all") {
    for (let el of this.elem.nativeElement.querySelectorAll(`[data-${type}]`)) {
      switch (type) {
        case "productVersion":
          if (this.filterData.products.includes(el.getAttribute(`data-${type}`)) && this.filterData.products.includes(el.getAttribute(`data-product`))) {
            el.style.display = 'flex';
          } else {
            el.style.display = 'none';
          }
          break;
        case "product":
          if (this.filterData.products.includes(el.getAttribute(`data-${type}`)) && this.filterData.products.includes(el.getAttribute(`data-productVersion`))) {
            el.style.display = 'flex';
          } else {
            el.style.display = 'none';
          }
          break;
        case "criterion":
          if (this.filterData.criteria.includes(el.getAttribute(`data-${type}`)) && this.filterData.criteria.includes(el.getAttribute(`data-criterioncategory`))) {
            el.style.display = 'flex';
          } else {
            el.style.display = 'none';
          }
          break;
        case "criterioncategory":
          if (this.filterData.criteria.includes(el.getAttribute(`data-${type}`)) && (el.getAttribute(`data-criterion`) == null || this.filterData.criteria.includes(el.getAttribute(`data-criterion`)))) {
            el.style.display = 'flex';
          } else {
            el.style.display = 'none';
          }
          break;
        default:
          break;
      }
    }
    if (type == "all") {
      console.log("all filter")
      this.toggleByFilter('productVersion')
      this.toggleByFilter('product')
      this.toggleByFilter('criterion')
      this.toggleByFilter('criterioncategory')
    }
    this.toggleSemaforFilter();
  }

  semaforCheck(criterionKey, criterion, value){
    if(criterion.semaforChecked == value){
      criterion.semaforChecked = "";
    }else{
      criterion.semaforChecked = value;
    }
    if(criterion.semaforChecked != ""){
      this.filterData.semafor[criterionKey] = criterion[value+'Filter'];
    }else{
      delete this.filterData.semafor[criterionKey]
    }
    this.toggleSemaforFilter();
  }

  semaforFilter(event, productVersionIdArray, criterionKey, value){
    if(event.target.checked){
      if(this.filterData.semafor[criterionKey] === undefined){
        this.filterData.semafor[criterionKey] = {
          green: [],
          blue: [],
          red: []
        }
      }
      this.filterData.semafor[criterionKey][value] = productVersionIdArray;
    }else{
      this.filterData.semafor[criterionKey][value] = [];
    }
    this.toggleSemaforFilter();
  }

  toggleSemaforFilter(){
    let filterTempArr = []
    for (const key in this.filterData.semafor) {
      if (this.filterData.semafor.hasOwnProperty(key)) {
        const filteredArrays = this.filterData.semafor[key];
        // KEY LOGIC GREEN, BLUE, RED
        if(filteredArrays.green.length && filteredArrays.blue.length && filteredArrays.red.length){
          continue;
        }
        if(filteredArrays.green.length && !filteredArrays.blue.length && !filteredArrays.red.length){
          filterTempArr.push(...filteredArrays.green);
        }
        if(!filteredArrays.green.length && filteredArrays.blue.length && !filteredArrays.red.length){
          filterTempArr.push(...filteredArrays.blue);
        }
        if(!filteredArrays.green.length && !filteredArrays.blue.length && filteredArrays.red.length){
          filterTempArr.push(...filteredArrays.red);
        }
        if(filteredArrays.green.length && filteredArrays.blue.length && !filteredArrays.red.length){
          let GBArr = filteredArrays.green.filter(function(val) {
            return filteredArrays.blue.indexOf(val) != -1;
          });
          filterTempArr.push(...GBArr);
        }
        if(filteredArrays.green.length && !filteredArrays.blue.length && filteredArrays.red.length){
          let GRArr = filteredArrays.green.filter(function(val) {
            return filteredArrays.red.indexOf(val) != -1;
          });
          filterTempArr.push(...GRArr);
        }
        if(!filteredArrays.green.length && filteredArrays.blue.length && filteredArrays.red.length){
          let RBArr = filteredArrays.blue.filter(function(val) {
            return filteredArrays.red.indexOf(val) != -1;
          });
          filterTempArr.push(...RBArr);
        }
      }
    }
    for (let el of this.elem.nativeElement.querySelectorAll(`[data-productVersion]`)) {
      const fieldProductVersionId = el.getAttribute(`data-productVersion`);
      const fieldProductId = el.getAttribute(`data-product`);
      if(this.filterData.products.includes(fieldProductId) && this.filterData.products.includes(fieldProductVersionId) && filterTempArr.includes(fieldProductVersionId)){
        el.style.display = 'none';
      }
      else if(this.filterData.products.includes(fieldProductId) && this.filterData.products.includes(fieldProductVersionId) && !filterTempArr.includes(fieldProductVersionId)){
        el.style.display = 'flex';
      }
    }
  }

  productFilterToggle(event, productId, type: "product" | "productVersion") {
    console.log(event);
    if (event.target.checked) {
      if (!this.filterData.products.includes(productId)) {
        this.filterData.products.push(productId)
        if (type == "product") {
          this.filterData.productsLength++;
        }
      }

      /*if (type == 'productVersion') {
        this.comparisonStructure.products.forEach(product => {
          product.productVersions.forEach(productVersion => {
            if (productVersion.id == productId && productVersion.state == "Historická") {
              if (!this.filterData.historicalVersions.includes(productId)) {
                this.filterData.historicalVersions.push(productId)
                this.loadingData = true;
                this.comparisonService.listAnalyses(this.filterData.historicalVersions).subscribe(data => {
                  this.comparisonStructure = data.comparisonStructure;
                  this.userComparisonView = data.userComparisonView;
                  this.analyses = data.analyses
                  this.userViewInitialize(this.userComparisonView);
                  this.loadingData = false;
                })
              }
            }
          });
        });
      }*/

    } else {


      const indexOfProductElement = this.filterData.products.indexOf(productId);

      if (indexOfProductElement > -1) {
        this.filterData.products.splice(indexOfProductElement, 1);
        if (type == "product") {
          this.filterData.productsLength--;
        }
      }

      if (this.filterData.historicalVersions.includes(productId)) {
        const indexOfHistoricalVersionElement = this.filterData.historicalVersions.indexOf(productId);
        if (indexOfHistoricalVersionElement > -1) {
          this.filterData.historicalVersions.splice(indexOfHistoricalVersionElement, 1);
        }
      }
    }
    console.log(this.filterData.products)
    this.canShowPdfPreviw = this.filterData.productsLength <= this.minimalProductsLengthToShowPdfPrevie;
    this.toggleByFilter(type)
  }

  productFilterAll(products, checked: boolean) {
    const simulatedEvent = {
      target: {
        checked: checked
      }
    }
    products.forEach(product => {
      this.productFilterToggle(simulatedEvent, product.id, 'product')
    })
  }

  criteriaFilterAll(criteria, checked: boolean, criterionCategory) {
    const simulatedEvent = {
      target: {
        checked: checked
      }
    }
    criteria.forEach(criterion => {
      this.criteriaFilterToggle(simulatedEvent, criterionCategory, 'criterion', criterion.id);
    })
  }

  criteriaFilterToggle(event, criterionCategory, type: "criterion" | "criterioncategory", criterionId = "") {
    if (event.target.checked) {

      if (type == "criterion") {
        if (this.filterData.criteria.includes(criterionCategory.id)) {
          if (!this.filterData.criteria.includes(criterionId)) {
            this.filterData.criteriaLength++;
          }
        }
        if (!this.filterData.criteria.includes(criterionId)) {
          this.filterData.criteria.push(criterionId);
        }
      }
      if (type == "criterioncategory") {
        criterionCategory.criteria.forEach(criterion => {
          if (this.filterData.criteria.includes(criterion.id)) {
            this.filterData.criteriaLength++;
          }
        });
        this.filterData.criteria.push(criterionCategory.id)
      }

    } else {
      if (type == "criterion") {
        const index = this.filterData.criteria.indexOf(criterionId);
        if (index > -1) {
          this.filterData.criteria.splice(index, 1);
        }
        if (this.filterData.criteria.includes(criterionCategory.id)) {
          if (index > -1) {
            this.filterData.criteriaLength--;
          }
        }
      }
      if (type == "criterioncategory") {
        const index = this.filterData.criteria.indexOf(criterionCategory.id);
        if (index > -1) {
          this.filterData.criteria.splice(index, 1);
        }
        criterionCategory.criteria.forEach(criterion => {
          if (this.filterData.criteria.includes(criterion.id)) {
            this.filterData.criteriaLength--;
          }
        });
      }
    }
    this.toggleByFilter(type)
  }

  userViewInitialize(userView: IUserComparisonView) {
    this.exitPdfMode(); //komponenta nevyvolá ngOnDestroy pokud se změní pohled
    this.filterData.criteria = [];
    this.filterData.products = [...this.filterData.historicalVersions];
    this.filterData.criteriaCount = 0;
    this.filterData.criteriaLength = 0;
    this.filterData.productsCount = 0;
    this.filterData.productsLength = 0;
    if (userView._id === "all") {
      this.comparisonStructure.products.forEach(product => {
        if (product.state == "Live") {
          this.filterData.products.push(product.id);
          this.filterData.productsCount++;
          this.filterData.productsLength++;
          product.productVersions.forEach(productVersion => {
            if (productVersion.state == "Aktuální" || productVersion.state == "Neaktuální") {
              this.filterData.products.push(productVersion.id);
            }
          });
        }
      });
      this.comparisonStructure.criterionCategories.forEach(criterionCategory => {
        this.filterData.criteria.push(criterionCategory.id);
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;
          this.filterData.criteriaLength++;
          this.filterData.criteria.push(criterion.id);
        });
      })
    } else if (userView._id === "rzp") {
      this.comparisonStructure.products.forEach(product => {
        if (product.state == "Live") {
          if (product.subtype == "RŽP") {
            this.filterData.products.push(product.id);
            this.filterData.productsLength++;
          }
          product.productVersions.forEach(productVersion => {
            if (productVersion.state == "Aktuální" || productVersion.state == "Neaktuální") {
              this.filterData.products.push(productVersion.id);
            }
          });
          this.filterData.productsCount++;
        }
      });
      this.comparisonStructure.criterionCategories.forEach(criterionCategory => {
        this.filterData.criteria.push(criterionCategory.id);
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;
          this.filterData.criteriaLength++;
          this.filterData.criteria.push(criterion.id);
        });
      })
    } else if (userView._id === "izp") {
      this.comparisonStructure.products.forEach(product => {
        if (product.state == "Live") {
          if (product.subtype == "IŽP") {
            this.filterData.products.push(product.id);
            this.filterData.productsLength++;
          }
          product.productVersions.forEach(productVersion => {
            if (productVersion.state == "Aktuální" || productVersion.state == "Neaktuální") {
              this.filterData.products.push(productVersion.id);
            }
          });
          this.filterData.productsCount++;
        }
      });
      this.comparisonStructure.criterionCategories.forEach(criterionCategory => {
        this.filterData.criteria.push(criterionCategory.id);
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;
          this.filterData.criteriaLength++;
          this.filterData.criteria.push(criterion.id);
        });
      })
    } else {
      this.filterData.products = [...userView.productFilter];
      this.comparisonStructure.products.forEach(product => {
        if (product.state == "Live") {
          if (this.filterData.products.includes(product.id)) {
            this.filterData.productsLength++;
          }
          this.filterData.productsCount++
        }
      });
      this.comparisonStructure.criterionCategories.forEach(criterionCategory => {
        criterionCategory.criteria.forEach(criterion => {
          this.filterData.criteriaCount++;
          if (userView.criterionFilter.includes(criterionCategory.id) && userView.criterionFilter.includes(criterion.id)) {
            this.filterData.criteriaLength++;
          }
        });
      })
      this.filterData.criteria = userView.criterionFilter
    }
    this.filterDataReady = true;
    this.canShowPdfPreviw = this.filterData.productsLength <= this.minimalProductsLengthToShowPdfPrevie;
  }

  countInArray(array, b) {
    let occur = 0;
    if (b) {
      array.forEach(e => {
        if (this.filterData.criteria.includes(e.id)) {
          occur++;
        }
      });
    } else {
      array.forEach(e => {
        if (this.filterData.products.includes(e.id)) {
          occur++;
        }
      });
    }
    return occur;
  }

  getYesterday() {
    return moment().subtract(1, 'day').format("D. M. YYYY");
  }

  validateUnigueName(updates: boolean = false){
    const name : string = this.name.value;
    const viewId = updates? this.userComparisonView._id: "emptyId";
    const productType = this.comparisonStructure._id;
    const isUnique = this.comparisonComponent.comparisonMenu.isUniqueViewName(viewId, productType, name.trim());
    if (isUnique) {
      this.saveViewForm.controls['name'].setErrors(null);
    } else {
      this.saveViewForm.controls['name'].setErrors({'isNotUnique': true});
    }
  }
}

export interface Theme {
  name: string;
  properties: any;
}

export const eucs: Theme = {
  name: "eucs",
  properties: {
    "--foreground-default": "#424242",
    "--foreground-secondary": "#323232",
    "--foreground-tertiary": "#222222",
    "--foreground-quaternary": "#111111",
    "--foreground-light": "#525252",
  
    "--background-default": "#f2f2f2",
    "--background-secondary": "#dfdfdf",
    "--background-tertiary": "#c4c4c4",
    "--background-light": "#ffffff",
  
    "--primary-default": "#000080",
    "--primary-dark": "#2439b2",
    "--primary-light": "#b2c7ff",
    "--primary-text": "#eeeeee",
  
    "--secondary-default": "#40A0FF",
    "--secondary-dark": "#001fcf",
    "--secondary-light": "#628bfd",
    "--secondary-text": "#eeeeee",

    "--error-default": "#ef5350",
    "--error-dark": "#b71c1c",
    "--error-light": "#ffcdd2",

    "--warrning-default": "#ffa726",
    "--warrning-dark": "#fb8c00",
    "--warrning-light": "#ffe0b2",

    "--success-default": "#66bb6a",
    "--success-dark": "#388e3c",
    "--success-light": "#c8e6c9",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)"
  }
};

export const light: Theme = {
  name: "light",
  properties: {
    "--foreground-default": "#424242",
    "--foreground-secondary": "#323232",
    "--foreground-tertiary": "#222222",
    "--foreground-quaternary": "#111111",
    "--foreground-light": "#525252",
  
    "--background-default": "#f2f2f2",
    "--background-secondary": "#dfdfdf",
    "--background-tertiary": "#c4c4c4",
    "--background-light": "#ffffff",
  
    "--primary-default": "#0bb4ff",
    "--primary-dark": "#2439b2",
    "--primary-light": "#b2c7ff",
    "--primary-text": "#eeeeee",
  
    "--secondary-default": "#056dec",
    "--secondary-dark": "#001fcf",
    "--secondary-light": "#628bfd",
    "--secondary-text": "#eeeeee",

    "--error-default": "#ef5350",
    "--error-dark": "#b71c1c",
    "--error-light": "#ffcdd2",

    "--warrning-default": "#ffa726",
    "--warrning-dark": "#fb8c00",
    "--warrning-light": "#ffe0b2",

    "--success-default": "#66bb6a",
    "--success-dark": "#388e3c",
    "--success-light": "#c8e6c9",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)"
  }
};

export const dark: Theme = {
  name: "dark",
  properties: {
    "--foreground-default": "#F5F5F5",
    "--foreground-secondary": "#A3B9CC",
    "--foreground-tertiary": "#F4FAFF",
    "--foreground-quaternary": "#E5E5E5",
    "--foreground-light": "#FFFFFF",

    "--background-default": "#1f272e",
    "--background-secondary": "#2b343b",
    "--background-tertiary": "#384047",
    "--background-light": "#797C80",

    "--primary-default": "#0bb4ff",
    "--primary-dark": "#2439b2",
    "--primary-light": "#b2c7ff",
    "--primary-text": "#eeeeee",

    "--secondary-default": "#056dec",
    "--secondary-dark": "#001fcf",
    "--secondary-light": "#628bfd",
    "--secondary-text": "#eeeeee",

    "--error-default": "#ef5350",
    "--error-dark": "#b71c1c",
    "--error-light": "#ffcdd2",

    "--warrning-default": "#ffa726",
    "--warrning-dark": "#fb8c00",
    "--warrning-light": "#ffe0b2",

    "--success-default": "#66bb6a",
    "--success-dark": "#388e3c",
    "--success-light": "#c8e6c9",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(8, 9, 10, 0.5)"
  }
};

export const budac: Theme = {
  name: "budac",
  properties: {
    "--primary-default": "#00965e",
    "--primary-light": "#69f0ae",
    "--secondary-default": "#777"
  }
};

export const kapitol: Theme = {
  name: "kapitol",
  properties: {
    "--primary-default": "#00965e",
    "--primary-light": "#69f0ae",  
    "--secondary-default": "#777"
  }
};

export const mmf: Theme = {
  name: "mmf",
  properties: {
    "--primary-default": "#028701",
    "--primary-light": "#46b300",  
    "--secondary-default": "#dd2a1a"
  }
};

export const freedom: Theme = {
  name: "freedom",
  properties: {
    "--primary-default": "#039be5",
    "--primary-light": "#40c4ff",
    "--secondary-default": "#777"
  }
};

export const dreams: Theme = {
  name: "dreams",
  properties: {
    "--primary-default": "#0d8d3b",
    "--primary-light": "#69f0ae",  
    "--secondary-default": "#777"
  }
};

export const smsfinance: Theme = {
  name: "smsfinance",
  properties: {
    "--primary-default": "#db4638",
    "--primary-light": "#ff5252",
    "--secondary-default": "#777"
  }
};

export const finpos: Theme = {
  name: "finpos",
  properties: {
    "--primary-default": "#e31e24",
    "--primary-light": "#ff5252",
    "--secondary-default": "#777"
  }
};

export const brokerpool: Theme = {
  name: "broker-pool",
  properties: {
    "--primary-default": "#5d4a4d",
    "--primary-light": "#eee6b0",
    "--secondary-default": "#9d8c73"
  }
};

export const allrisk: Theme = {
  name: "allrisk",
  properties: {
    "--primary-default": "#004c93",
    "--primary-light": "#448aff",
    "--secondary-default": "#777"
  }
};

export const finvision: Theme = {
  name: "finvision",
  properties: {
    "--primary-default": "#143c81",
    "--primary-light": "#448aff",
    "--secondary-default": "#777"
  }
};

export const sirius: Theme = {
  name: "sirius",
  properties: {
    "--primary-default": "#bb4849",
    "--primary-light": "#ffab40",
    "--secondary-default": "#d7d7d7"
  }
};

export const fincentrum: Theme = {
  name: "fincentrum",
  properties: {
    "--primary-default": "#b51f2a",
    "--primary-light": "#ff5252",
    "--secondary-default": "#777"
  }
};

export const edofinance: Theme = {
  name: "edofinance",
  properties: {
    "--primary-default": "#734C24",
    "--primary-light": "#FFE0B0",
    "--secondary-default": "#777",
  }
};

export const swisslife: Theme = {
  name: "swisslife",
  properties: {
    "--primary-default": "#b51f2a",
    "--primary-light": "#ff5252",
    "--secondary-default": "#777"
  }
};

export const collegas: Theme = {
  name: "collegas",
  properties: {
    "--primary-default": "#71BF45",
    "--primary-light": "#B9BABC",
    "--secondary-default": "#414244",
  }
};

export const themeList: Theme[] = [light,dark,budac,mmf,kapitol,freedom,dreams,smsfinance,finpos,brokerpool,allrisk,finvision,sirius,fincentrum,edofinance,swisslife,collegas]

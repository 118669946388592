import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.sass']
})
export class ProjectSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IProductType } from 'src/app/models/productType.model';
import { IUserComparisonView } from 'src/app/models/userComparisonView.model';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'comparison-menu',
  templateUrl: './comparison-menu.component.html',
  styleUrls: ['./comparison-menu.component.scss'],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class ComparisonMenuComponent implements OnInit {

  productTypes: IProductType[] = [];
  userComparisonViews: IUserComparisonView[] = [];
  unfilteredUserComparisonViews: IUserComparisonView[] = [];
  productTypeList: Observable<IProductType[]>;
  userComparisonViewList: Observable<IUserComparisonView[]>;
  productTypeListAndUserComparisonViewList: Observable<{
    productTypes: IProductType[],
    userComparisonViewList: IUserComparisonView[]
  }>
  loadingData: boolean = true;
  filteringViews : boolean = false;

  constructor(private http: HttpClient, private ar: ActivatedRoute) {
    this.productTypeList = this.http.post<IProductType[]>('/app/productType/list', {});
    this.userComparisonViewList = this.http.post<IUserComparisonView[]>('/app/userComparisonView/list', {});

    this.productTypeListAndUserComparisonViewList = this.productTypeList.pipe(
      switchMap((productTypes: IProductType[]) => {
        return this.userComparisonViewList.pipe(
          map((userComparisonViewList: IUserComparisonView[]) => {
            return { productTypes, userComparisonViewList };
          })
        )
      })
    )
  }

  ngOnInit() {
    this.productTypeList.subscribe((data) => {
      this.productTypes = data;
    })
    this.reloadMenuData();
  }

  activateMenu(productTypeId) {
    this.productTypes.forEach((productType: any) => {
      if (productType._id == productTypeId) {
        productType.active = true;
      }
    })
  }
  reloadMenuData() {
    this.userComparisonViewList.subscribe((data) => {
      this.userComparisonViews = data.sort((a, b) => (a.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1);
      this.unfilteredUserComparisonViews = this.userComparisonViews;
      this.loadingData = false
    })
  }

  filter(value: string) { 
    const filterValue = value.toLowerCase().trim();
    this.userComparisonViews = this.unfilteredUserComparisonViews.filter(option => option['name'].toLowerCase().includes(filterValue));
  }
  openSearchBox(){
    this.filteringViews = !this.filteringViews;
    if(this.filteringViews){
      this.productTypes.forEach((productType: any) => {
        productType.active = true;
      })
    } 
    else{
      this.userComparisonViews = this.unfilteredUserComparisonViews;
      this.productTypes.forEach((productType: any) => {
        if(productType._id != this.ar.snapshot.children[0].paramMap.get('productTypeId'))
        productType.active = false;
      })
    }  
  }

  isUniqueViewName(viewId: string,viewProductTypeId: string , viewName: string) : boolean{
    if(viewName == "Základní pohled" || viewName == "Rizikové životní pojištění" || viewName == "Investiční životní pojištění"){
      return false;
    }
    let filteredView = this.unfilteredUserComparisonViews.find(view=>(view.name == viewName && view._id != viewId && view.productType == viewProductTypeId));
    return filteredView == undefined? true : false;
  }
}

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ComparisonMenuComponent } from './comparison-menu/comparison-menu.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {

  sidenavOpen: boolean = true;
  @ViewChild(ComparisonMenuComponent, { static: true }) comparisonMenu: ComparisonMenuComponent;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.closeSideNavOnSmallMonitorWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closeSideNavOnSmallMonitorWidth();
  }
  
  toggleMenu(){
    this.sidenavOpen = !this.sidenavOpen;
  }

  closeSideNavOnSmallMonitorWidth() {
    this.sidenavOpen = window.innerWidth <= 1280 ? false : true;
  }
}

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { QuestionnaireMenuComponent } from './questionnaire-menu/questionnaire-menu.component';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  sidenavOpen: boolean = true;
  @ViewChild(QuestionnaireMenuComponent, { static: true }) questionnaireMenu: QuestionnaireMenuComponent;

  constructor() { }

  ngOnInit() {
    this.closeSideNavOnSmallMonitorWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closeSideNavOnSmallMonitorWidth();
  }
  
  toggleMenu() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  closeSideNavOnSmallMonitorWidth() {
    this.sidenavOpen = window.innerWidth <= 1280 ? false : true;
  }
}

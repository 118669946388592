import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { AuthenticationService } from 'core-client/core/authentication.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as moment from "moment";
import { FeedbackComponent } from '../feedback.component';
import { IComparisonStructure } from 'src/app/models/comparisonStructure.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-feedback-criteria',
  templateUrl: './feedback-criteria.component.html',
  styleUrls: ['./feedback-criteria.component.scss'],
  providers: [FeedbackService],
  animations: [
    trigger('loaderFadeOut', [
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class FeedbackCriteriaComponent implements OnInit, OnDestroy {

  urlChangeSubscription: Subscription;

  loadingData: boolean = true;
  currentUserName: string = "";
  currentUserId: string = "";
  feedbackStructure: IComparisonStructure;
  objectKeys = Object.keys;
  criteria: any;
  chatCriterionHeader: string = "";
  chatCriterionMessages: any;
  chatCriterionId: string = "";
  chatOpened: boolean = false;
  biggerChat: boolean = false;
  private notificationCriterionId: string = '';

  constructor(
    public feedbackComponent: FeedbackComponent,
    private router: Router,
    private ar: ActivatedRoute,
    private feedbackService: FeedbackService,
    private elem: ElementRef,
    private authService: AuthenticationService,
    private http: HttpClient
  ) {
    this.ar.queryParams.subscribe(params => {
      this.notificationCriterionId = params['criterionId'];
    });
    this.urlChangeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(e => {
      this.loadingData = true;
      this.feedbackService.listCriteria().subscribe(data => {
        this.feedbackStructure = data.feedbackStructure;
        this.criteria = data.criteria;
        this.loadingData = false;
      });
    })
  }

  ngOnInit() {
    this.currentUserName = this.authService.getTokenPayload().displayName;
    this.currentUserId = this.authService.getTokenPayload()._id;
    console.log(this.ar);
  }


  showChat(criterionId, criterionHeader, criterionMessages) {
    this.chatCriterionId = criterionId;
    this.chatCriterionHeader = criterionHeader;
    this.chatCriterionMessages = criterionMessages;
    this.chatOpened = true;
  }
  addMessage(message) {
    console.log(message);
    this.feedbackService.updateCriterionChat(this.chatCriterionId, message).subscribe((res: any) => {
      this.http.post(`/app/feedbackNotification/create`, {
        toProject: 'admin',
        productTypeId: this.feedbackStructure._id,
        productId: '-',
        notificationType: 'comment',
        feedbackType: 'criterion',
        productVersionId: '-',
        activeItemId: this.chatCriterionId
      }).subscribe(res => {
        console.log(res)
      });
    })
  }

  reopenChat() {
    this.chatOpened = false;
    setTimeout(() => {
      this.chatOpened = true;
    }, 100);
  }

  ngOnDestroy() {
    this.urlChangeSubscription.unsubscribe();
  }
}

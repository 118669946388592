import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AuthenticationService } from './authentication.service';
import { NetstatService } from './netstat.service';
import { SocketService } from './socket.service';
import { TokenInterceptor } from './token.interceptor';
import { ProjectService } from './project.service';
import { OpenHoursComponent } from './utilities/elements/open-hours/open-hours.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemePickerComponent } from './utilities/elements/theme-picker/theme-picker.component';
import { ContactComponent } from './utilities/elements/contact/contact.component';
import { TabsComponent,TabComponent } from './utilities/elements/tabs/tabs.component';
import { BrowserModule } from '@angular/platform-browser';
import { CustomSelectComponent,CustomSelectOptionDirective, CustomSelectRequiredDirective } from './utilities/elements/custom-select/custom-select.component';
import { PopupComponent } from './utilities/elements/popup/popup.component';
import { CustomTooltipComponent, CustomTooltipDirective } from './utilities/elements/custom-tooltip/custom-tooltip.component';
import { Overlay } from '@angular/cdk/overlay';
import { ChipsComponent, ChipComponent, ChipInputDirective } from './utilities/elements/chips/chips.component';
import { BasicChatComponent } from './utilities/elements/basic-chat/basic-chat.component';

const config: SocketIoConfig = { url: '', options: {} };

@NgModule({
  declarations: [
    OpenHoursComponent,
    ThemePickerComponent,
    ContactComponent,
    TabsComponent,
    TabComponent,
    CustomSelectComponent,
    CustomSelectOptionDirective,
    CustomSelectRequiredDirective,
    PopupComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    ChipsComponent,
    ChipComponent,
    ChipInputDirective,
    BasicChatComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    SocketIoModule.forRoot(config),
    FormsModule,
    ReactiveFormsModule,
    
  ],
  exports: [
    OpenHoursComponent,
    ThemePickerComponent,
    ContactComponent,
    TabsComponent,
    TabComponent,
    CustomSelectComponent,
    CustomSelectOptionDirective,
    CustomSelectRequiredDirective,
    PopupComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    ChipsComponent,
    ChipComponent,
    ChipInputDirective,
    BasicChatComponent
  ],
  providers: [
    AuthenticationService,
    NetstatService,
    SocketService,
    ProjectService,
    Overlay,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  entryComponents:[
    CustomTooltipComponent
  ]
})
export class CoreModule { }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserComparisonView } from 'src/app/models/userComparisonView.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'questionnaire-menu',
  templateUrl: './questionnaire-menu.component.html',
  styleUrls: ['./questionnaire-menu.component.scss']
})
export class QuestionnaireMenuComponent implements OnInit {

  userQuestionnaireViewObs: Observable<any[]>;
  userQuestionnaireViewList: any[];
  unfilteredUserQuestionnaireViewList: any[];
  filteringViews: boolean = false;

  constructor(
    private http: HttpClient
  ) {
    this.userQuestionnaireViewObs = this.http.post<IUserComparisonView[]>('/app/userQuestionnaireView/list', {});
    
  }

  ngOnInit() {
    this.reloadMenuData();
  }

  reloadMenuData() {
    this.userQuestionnaireViewObs.subscribe((data) => {
      this.userQuestionnaireViewList = data.sort((a, b) => (a.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1);
      this.unfilteredUserQuestionnaireViewList = this.userQuestionnaireViewList;
    })
  }

  filter(value: string)  {
    const filterValue = value.toLowerCase().trim();
    this.userQuestionnaireViewList = this.unfilteredUserQuestionnaireViewList.filter(option => option['name'].toLowerCase().includes(filterValue));
  }

  openSearchBox(){
    this.filteringViews = !this.filteringViews;
    if(!this.filteringViews){
      this.userQuestionnaireViewList = this.unfilteredUserQuestionnaireViewList;
    }
  }
  isUniqueViewName(viewId: string,viewProductTypeId: string , viewName: string) : boolean{
    if(viewName == "Základní pohled" || viewName == "Rizikové životní pojištění" || viewName == "Investiční životní pojištění"){
      return false;
    }
    let filteredView = this.unfilteredUserQuestionnaireViewList.find(view=>(view.name == viewName && view._id != viewId && view.productType == viewProductTypeId));
    return filteredView == undefined? true : false;
  }
}

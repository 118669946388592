import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewChecked, AfterContentInit, AfterContentChecked, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

export interface IBasicMessage {
  user: {
    displayName: string,
    _id: string
  },
  createdAt: Date,
  messageText: string
}

@Component({
  selector: 'basic-chat',
  templateUrl: './basic-chat.component.html',
  styleUrls: ['./basic-chat.component.scss']
})
export class BasicChatComponent implements OnInit {
  
  messageForm: FormGroup;

  constructor(private fb: FormBuilder, private cDRef: ChangeDetectorRef) { }

  @Input() messages: IBasicMessage[] = [];
  @Input() chatHeader: string = "";

  @Input() currentUserId: string = "";
  @Input() currentUserName: string = "";

  @Output() newMessage = new EventEmitter();


  get messageText() { return this.messageForm.get('messageText') }

  ngOnInit() {
    this.messageForm = this.fb.group({
      'messageText': ["", [
        Validators.required
      ]
      ]
    });
    if (!this.currentUserName || !this.currentUserId) {
      throw new Error('Chybí jméno uživatele nebo projectu');
    }
  }

  addMessage() {
    const messageText = this.messageText.value.trim();
    if (messageText && this.currentUserName && this.currentUserId) {
      const newMessage: IBasicMessage = {
        user:{
          displayName: this.currentUserName,
          _id: this.currentUserId
        },
        messageText: messageText,
        createdAt: new Date()
      }
      this.messages.push(newMessage);
      this.newMessage.emit(newMessage);
    }
    this.messageText.setValue("");
  }
}
